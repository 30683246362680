import {
  Box,
  Chip,
  Paper,
  Typography,
  AppBar,
  Button,
  List,
  tooltipClasses,
  Tooltip,
  Grid,
  TextField,
  Drawer,
  Tab,
  Tabs,
  Toolbar,
  CSSObject,
  Theme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ContainerWrapperProps,
  FilterFormContainerProps,
  FormFooterInterface,
  PageProps,
  ThemeInfoStyleProps,
} from 'src/interfaces';

import {
  AmountProps, CreateDealFormContainerStyledProps,
  DrawerHeaderProps,
  StyledButtonProps,
  StyledCardContainerProps,
  StyledCardProps, StyledPaperProps, StyledPopupContainer, StyledTableChipsContentContainer,
  StyledTableStubContentContainer,
  StyledTabProps,
  StyledTabsProps, StyledTabSwitchContainer, StyledValue,
} from 'src/interfaces/layoutInterfaces';

export const Amount = styled(Typography, { shouldForwardProp: (prop) => prop !== 'isDebt' })<AmountProps>(({ isDebt, isMobile }) => ({
  fontSize: '16px',
  fontWeight: 600,
  ...(isDebt && {
    color: '#D32020',
  }),
  ...(isMobile && {
    fontSize: '14px',
  }),
}));

export const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #E9E9E9',
  position: 'sticky',
  top: '-59px',
  height: 90,
  backgroundColor: '#FFFFFF',
});

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    scrollButtons={false}
    variant="scrollable"
    aria-label="scrollable prevent tabs example"
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
    height: '4px',
    border: '1px solid #0E79EC',
    borderRadius: '18px 18px 0px 0px',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#0E79EC',
  },
});

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ isMobile }) => ({
  fontSize: '16px',
  lineHeight: '32px',
  fontWeight: 600,
  color: '#676767',
  textTransform: 'inherit',
  '&.Mui-selected': {
    color: '#191919',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#fff',
  },
  ...(isMobile && {
    fontSize: '14px',
  }),
}));

export type Order = 'asc' | 'desc';

export const Circle = styled('div')({
  width: '10px',
  height: '10px',
  backgroundColor: '#0E79EC',
  borderRadius: '50%',
});

export const NewStatusContainer = styled('div')<any>(({ isMobile }) => ({
  display: 'flex',
  alignItems: 'center',
  '& div': {
    marginRight: '8px',
  },
  ...(isMobile && {
    color: '#0E79EC',
  }),
}));

export const MinimizedNomenclatures = styled('div')({
  color: '#0E79EC',
  fontWeight: 600,
});

export const NomenclaturesPopover = styled('div')({
  padding: '16px',
});

const drawerWidth = 265;

export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

export const DrawerHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open',
})<DrawerHeaderProps>(({ open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '16px 16px',
  fontSize: 24,
  fontWeight: 900,
  height: '64px',
  ...(!open && {
    padding: '16px 10px 16px 10px',
  }),
}));

export const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const Role = styled(Typography)({
  component: 'div',
  alignSelf: 'center',
  height: '100%',
  fontSize: '15px',
  marginTop: '3px',
  marginLeft: '6px',
  color: '#676767',
  fontWeight: 600,
  textTransform: 'uppercase',

});

export const FormFooter = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'justifyRight',
})<FormFooterInterface>(({ justifyRight, isMobile }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px 32px',
  borderTop: '1px solid #E9E9E9',
  ...(justifyRight && {
    justifyContent: 'flex-end',
  }),
  ...(isMobile && {
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '16px 24px',
  }),
}));

export const FormHeader = styled(Box)<PageProps>(({ isMobile }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '23px 19px 22px 32px',
  borderBottom: '1px solid #E9E9E9',
  ...(isMobile && {
    padding: '16px 24px',
  }),
}));

export const ButtonMobile = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

});

export const BurgerMenuHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: '15px',
  borderRadius: '8px 8px 0px 0px',
  backgroundColor: 'white',
  borderBottom: '1px solid #E9E9E9',
  '& p': {
    fontSize: '18px',
    color: '#000000',
    fontWeight: 600,
    margin: '0px',
  },
  '& svg': {
    cursor: 'pointer',
  },
});

export const MainContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<FilterFormContainerProps>(({ open, isMobile }) => ({
  paddingLeft: 57,
  backgroundColor: '#F8F8F8',
  transition: '0.25s',
  position: 'relative',
  // TODO вернуть если нужен будет скейлинг

  // [`@media(min-width: ${BREAKPOINTS.laptop})`]: {
  //   width: '125%',
  //   height: '124.5%',
  //   transform: 'scale(.8)',
  //   webkitTransform: 'scale(.8)',
  //   '-moz-transform': 'scale(.8)',
  //   '-ms-transform': 'scale(.8)',
  //   marginLeft: '-12.5%',
  //   marginTop: '-9.4%',
  // },
  // [`@media(min-width: ${BREAKPOINTS.laptopM})`]: {
  //   marginLeft: '-12.5%',
  //   marginTop: '-7%',
  // },
  // [`@media(min-width: ${BREAKPOINTS.laptopL})`]: {
  //   marginLeft: '-12.5%',
  //   marginTop: '-7.9%',
  // },
  // [`@media(min-width: ${BREAKPOINTS.desktop})`]: {
  //   marginLeft: '-12.5%',
  //   marginTop: '-7%',
  // },
  // [`@media(min-width: ${BREAKPOINTS.desktopM})`]: {
  //   marginLeft: '-12.5%',
  //   marginTop: '-6.5%',
  // },
  ...(open && {
    paddingLeft: !isMobile ? 264 : 0,
  }),

}));

export const InfoBox = styled(Box, {
  shouldForwardProp: (light) => light !== 'light',
})<ThemeInfoStyleProps>(({ light }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'flex-start',
  backgroundColor: '#E9E9E9',
  padding: '8px 16px',
  borderRadius: '8px',
  ...(light && {
    minWidth: '330px',
    backgroundColor: 'white',
  }),
}));

export const StyledInput = styled(TextField)({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    height: 40,
    borderRadius: 6,
    fontSize: 16,
    marginRight: 24,
    '& svg': {
      marginRight: 10,
    },
  },
});

export const Data = styled(Box)<{isMobile?: boolean}>({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const Title = styled(Typography)({
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: '600',
  color: '#676767',
  marginRight: '8px',
});

export const MobileCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'light',
})<StyledCardProps>(() => ({
  backgroundColor: '#FFFFFF',
  marginBottom: '8px',
  border: '1px solid #E9E9E9',
  borderRadius: '8px',
  fontSize: '14px',
  '& .MuiCollapse-wrapper .MuiCollapse-wrapperInner': {
    width: '100%',
  },
}));

export const StyledCardContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'light',
})<StyledCardContainerProps>(() => ({
  width: '100%',
}));

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'light',
})<StyledButtonProps>(({ light, isMobile }) => ({
  backgroundColor: '#1E95FF',
  color: '#FFFFFF',
  fontWeight: 600,
  padding: '10px 16px',
  fontSize: '16px',
  borderRadius: 6,
  height: 40,
  textTransform: 'none',
  whiteSpace: 'nowrap',
  '&.Mui-disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.26)',
    color: 'rgba(0, 0, 0, 0.12)',
  },
  ':hover': {
    backgroundColor: '#0E79EC',
  },
  ...(light && {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    border: '1px solid #E9E9E9',
  }),
  ...(isMobile && {
    border: 'none',
  }),
}));

export const ErrorMessage = styled(Typography)({
  color: '#d32f2f',
  fontSize: '13px',
  marginTop: '3px',
});

export const TableChipsContainer = styled(Box)<StyledTableChipsContentContainer>(
  () => ({
    minWidth: '100px',
    width: '100%',
    flexWrap: 'wrap',
  }),
);

export const TableStubContentContainer = styled(Box)<StyledTableStubContentContainer>(
  ({ isFilterOpen }) => ({
    display: 'flex',
    minWidth: '100px',
    width: '100%',
    position: 'relative',
    flexDirection: 'column',
    ...(isFilterOpen && {
      width: '75%',
    }),
  }),
);

export const StyledChip = styled(Chip)<StyledTableStubContentContainer>(({ isMobile }) => ({
  borderRadius: '4px',
  marginBottom: '12px',
  marginRight: '8px',
  height: 'fit-content',
  maxWidth: 'none',
  '& svg': {
    flexShrink: 0,
  },
  '& p': {
    whiteSpace: 'nowrap',
  },
  ...(isMobile && {
    margin: '0px 24px 16px 0px',
  }),
}));

export const PopupContainer = styled(Box)<StyledPopupContainer>(
  ({ isMobile, transform, sx }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform,
    maxHeight: '90vh',
    overflowY: 'scroll',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    ...sx,
    ...(isMobile && {
      width: '100%',
      transform,
    }),
  }),
);

export const Wrapper = styled(Box, {
  shouldForwardProp: (orientation) => orientation !== 'vertical',
})<ContainerWrapperProps>(({ orientation, isMobile, height }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: 'calc(100vh - 90px)',
  justifyContent: 'center',
  backgroundColor: '#F8F8F8',
  ...(height) && {
    height,
    overflowY: 'auto',
  },
  ...(orientation && {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }),
  '& .MuiCollapse-wrapperInner': {
    width: '100%',
  },
  ...(isMobile && {
    height: 'calc(100vh - 277px)',
    ...(height) && {
      height,
      overflowY: 'auto',
    },
    overflowY: 'auto',
    '& form': {
      width: '100%',
    },
  }),
}));

export const FilterContainer = styled(Box, {
  shouldForwardProp: (orientation) => orientation !== 'vertical',
})<ContainerWrapperProps>(({ isMobile }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  backgroundColor: '#F8F8F8',
  '& .MuiCollapse-wrapperInner': {
    width: '100%',
  },
  ...(isMobile && {
    '& form': {
      width: '100%',
    },
  }),
}));

export const FilterHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  '& p': {
    fontSize: '18px',
    color: '#000000',
    fontWeight: 600,
    margin: '0px',
  },
  '& svg': {
    cursor: 'pointer',
  },
});

export const EditDealFormGrid = styled(Grid)({
  paddingTop: '10px',
});

export const Divider = styled(Grid)({
  borderBottom: '1px solid #E9E9E9',
  margin: '20px 0px 20px 0px',
});

export const StyledTitle = styled(Typography)({
  fontSize: '16px',
  fontWeight: 600,
  cursor: 'pointer',
});

export const FormTitle = styled(Typography)({
  fontSize: '18px',
  fontWeight: 600,
  marginRight: '16px',
  width: '90%',
});

export const StyledStatus = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'canceled',
})<{ canceled?: boolean }>(({ canceled }) => ({
  fontWeight: 600,
  display: 'flex',
  flexWrap: 'wrap',
  color: '#676767',
  '& span:nth-child(odd)': {
    marginRight: '10px',
  },
  '& span:nth-child(even)': {
    display: 'flex',
    color: '#000',
  },
  ...(canceled && {
    '& span': {
      color: '#D32020',
    },
  }),
}));

export const DocumentName = styled(Typography)({
  fontSize: '14px',
  fontWeight: 600,
  color: '#676767',
  margin: '24px 0px 12px 0px',
});

export const LightTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    maxWidth: 350,
  },
}));

export const CustomListSideBar = styled(List, {})(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',

  '& .MuiListItemButton-root': {
    lineHeight: 24,
    borderRadius: 6,
    fontSize: '14px',
    '& .MuiListItemText-root': {
      '& .MuiTypography-root': {
        fontSize: '14px',
      },
    },
    '&:hover': {
      backgroundColor: '#EEF7FF',
      '& .MuiListItemIcon-root': {
        '& svg': {
          '& path': {
            fill: '#1E95FF',
          },
        },
      },
    },
  },
  '& .MuiListItemText-root': {
    '& .MuiTypography-root': {
      fontWeight: 600,
      fontSize: 16,
      color: 'black',
    },

  },
}));

export const CustomListTabBar = styled(List, {})(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 'max-content',
  backgroundColor: 'white',
  width: '100%',

  '& .MuiListItemButton-root': {
    width: 'max-content',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    '& .MuiListItemText-root': {
      '& .MuiTypography-root': {
        fontSize: '12px',
        fontWeight: 500,
      },
    },
    '&:hover': {
      '& .MuiListItemIcon-root': {
        '& svg': {
          '& path': {
            fill: '#1E95FF',
          },
        },
      },
      '& .MuiListItemText-root': {
        '& .MuiTypography-root': {
          color: '#1E95FF',
        },
      },
    },
  },
}));

export const CustomList = styled(List, {})(() => ({
  '& .MuiListItemButton-root': {
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

export const SectionTitle = styled(Typography)({
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 600,
  color: '#676767',
});

export const StyledPaper = styled(Paper)<StyledPaperProps>(({ theme, isMobile }) => ({
  maxWidth: isMobile ? 'none' : '508px',
  width: '100%',
  height: '100%',
  borderRadius: '16px',
  overflowY: 'auto',
  zIndex: theme.zIndex.drawer + 2,
  ...(isMobile && {
    borderRadius: '8px 8px 0px 0px',
  }),
}));

export const StyledAuthorizationPaper = styled(Paper)({
  width: 320,
  padding: 32,
  borderRadius: 16,
  boxShadow: '0px 4px 24px rgba(47, 57, 59, 0.15)',
});

export const Label = styled(Typography)({
  fontSize: '14px',
  color: '#676767',
});

export const Value = styled(Typography)<StyledValue>(({ isMobile }) => ({
  fontSize: '16px',
  color: '#191919',
  ...(isMobile && {
    fontSize: '12px',
  }),
}));

export const Block = styled(Box)({
  paddingBottom: '12px',
});

export const AppBarStyled = styled(AppBar)(() => ({
  height: 65,
  backgroundColor: '#FFFFFF',
  boxShadow: 'none',
  borderBottom: '1px solid #E9E9E9',
  position: 'sticky',
  top: '-122px',
}));

export const FilterFormContainer = styled(Box)<FilterFormContainerProps>(({ isMobile }) => ({
  width: '100%',
  backgroundColor: '#FFFFFF',
  padding: '16px',
  ...(isMobile && {
    width: '100%',
    marginLeft: 0,
    height: '100%',
    overflow: 'auto',
  }),
}));

export const TabSwitchContentContainer = styled(Box)<StyledTabSwitchContainer>(
  ({ isMobile }) => ({
    width: '100%',
    height: 'calc(100vh - 121px)',
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
    overflowY: 'auto',
    overflowX: 'hidden',
    ...(isMobile && {
      height: 'calc(100vh - 333px)',
    }),
  }),
);

export const CreateDealFormContainer = styled(Box)<CreateDealFormContainerStyledProps>(
  ({ isMobile }) => ({
    width: '100%',
    height: 'calc(100vh - 65px)',
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
    overflowY: 'auto',
    ...(isMobile && {
      height: 'calc(100vh - 200px)',
    }),
  }),
);

export const PageContentContainer = styled(Box)<CreateDealFormContainerStyledProps>(
  ({ isMobile }) => ({
    overflowY: 'auto',
    height: '100vh',
    ...(isMobile && {
      height: 'calc(100vh - 305px)',
    }),
  }),
);
