import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { actions } from 'src/store/snackbar/slice';

const Snackbar = ({
  id, variant, message, onRemove, ...props
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    enqueueSnackbar(
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box>{message}</Box>
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => closeSnackbar(id)}
        >
          <CloseIcon />
        </IconButton>
      </Box>,
      {
        ...props,
        key: id,
        variant,
        onExited: () => onRemove(id),
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      },
    );

    return () => closeSnackbar(id);
  }, []);

  return null;
};

const Snackbars = () => {
  const dispatch = useDispatch();
  const snackbars = useSelector((store: any) => store.snackbar.items, shallowEqual);
  const onRemove = (id) => dispatch(actions.removeSnackbar(id));

  return snackbars.map((props) => <Snackbar key={props.id} onRemove={onRemove} {...props} />);
};

export default Snackbars;
