import { createSlice } from '@reduxjs/toolkit';

const generateId = () => (+new Date()).toString(16);

const addSnackbar = (state, snackbar) => {
  state.items.push({ id: generateId(), ...snackbar });
};

const initialState = {
  items: [],
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackbar(state, { payload }) {
      addSnackbar(state, payload);
    },

    openSuccessSnackbar(state, { payload: message }) {
      addSnackbar(state, {
        variant: 'success',
        message,
        autoHideDuration: 5000,
      });
    },

    openErrorSnackbar(state, { payload: message }) {
      addSnackbar(state, {
        variant: 'error',
        message,
        autoHideDuration: 5000,
      });
    },

    openWarningSnackbar(state, { payload: message }) {
      addSnackbar(state, {
        variant: 'warning',
        message,
        autoHideDuration: 10000,
      });
    },

    removeSnackbar(state, { payload: snackbarId }) {
      state.items = state.items.filter(({ id }) => id !== snackbarId);
    },
  },
});

export const { actions } = snackbarSlice;

export default snackbarSlice.reducer;
