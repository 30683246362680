import { combineReducers } from 'redux';
import mainReducer from './main/slice';
import entityReducer from './entity/slice';
import snackbarReducer from './snackbar/slice';
import bidReducer from './bid/slice';
import nomenclatureReducer from './nomenclatures/slice';
import transportCompaniesReducer from './transportCompanies/slice';
import dealReducer from './deal/slice';
import contractorsReducer from './contractors/slice';
import legalEntityReducer from './legalEntities/slice';
import platformReducer from './platform/slice';
import dealShipmentReducer from './dealShipment/slice';
import expectedRepaymentReducer from './expectedRepayments/slice';
import walletReducer from './wallet/slice';
import actualRepaymentReducer from './actualRepayments/slice';
import actualIncomeReducer from './actualIncomes/slice';
import expectedIncomeReducer from './expectedIncomes/slice';
import cashRequestReducer from './cashRequest/slice';
import cashDepositReducer from './cashDeposit/slice';
import managerReducer from './manager/slice';

export default combineReducers({
  main: mainReducer,
  entity: entityReducer,
  snackbar: snackbarReducer,
  bid: bidReducer,
  nomenclature: nomenclatureReducer,
  transportCompanies: transportCompaniesReducer,
  contractors: contractorsReducer,
  platform: platformReducer,
  deal: dealReducer,
  legalEntity: legalEntityReducer,
  dealShipment: dealShipmentReducer,
  expectedRepayment: expectedRepaymentReducer,
  expectedIncome: expectedIncomeReducer,
  actualIncome: actualIncomeReducer,
  wallet: walletReducer,
  actualRepayment: actualRepaymentReducer,
  cashRequest: cashRequestReducer,
  cashDeposit: cashDepositReducer,
  manager: managerReducer,
});
