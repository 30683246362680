import {
  put, takeEvery, call,
} from 'redux-saga/effects';

import api from 'src/lib/api';
import getEntityProps from 'src/entities/props';
import { actions as snackbar } from 'src/store/snackbar/slice';
import { actions as entityActions } from 'src/store/entity/slice';
import getResponseErrorMessage from 'src/lib/utils';
import getSnackbarMessage from 'src/lib/snackbar';
import { ENTITY_TYPES } from 'src/constants';
import { actions } from './slice';
import parseRequest from '../../lib/parser/request';

const entityType = ENTITY_TYPES.ACTUAL_REPAYMENT_ENTITY;
const parseRequestData = (data) => parseRequest({ entityType, data });

function* createActualIncome({ payload }) {
  try {
    const endpoint = getEntityProps([entityType, 'endpoint']);
    const response = yield call(api.post, endpoint, { data: parseRequestData(payload) });
    yield put(snackbar.openSuccessSnackbar(`Фактическое поступление ${response.id} успешно создано`));
    yield put(entityActions.getListStart({ entityType, values: {} }));
    yield put(entityActions.getListStart({ entityType: 'ExpectedIncomeEntity', values: {} }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

function* getActualIncome({ payload }) {
  const { id } = payload;
  try {
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}${id}/`;
    const response = yield call(api.get, endpoint, {});
    yield put(actions.getActualIncomeSuccess({ response }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

function* editActualIncome({ payload }) {
  const { id } = payload;
  try {
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}${id}/`;
    const response = yield call(api.patch, endpoint, { data: parseRequestData(payload) });
    yield put(actions.editActualIncomeSuccess({ response }));
    yield put(snackbar.openSuccessSnackbar(`Ожидаемое поступление ${response.id} успешно обновлено`));
    yield put(entityActions.getListStart({ entityType, values: {} }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

export default function* sagas() {
  yield takeEvery(actions.createActualIncomeStart, createActualIncome);
  yield takeEvery(actions.getActualIncomeStart, getActualIncome);
  yield takeEvery(actions.editActualIncomeStart, editActualIncome);
}
