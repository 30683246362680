import { createSlice } from '@reduxjs/toolkit';
import { merge, reduce } from 'lodash';

import { ENTITY_TYPES } from 'src/constants';

const initialState = reduce(
  ENTITY_TYPES,
  (result) => {
    result = merge(
      {
        legalEntity: null,
        loading: false,
      },
    );
    return result;
  },
  {},
);

const slice = createSlice({
  name: 'legalEntity',
  initialState,
  reducers: {
    createLegalEntityStart(state, { payload }) { },
    createLegalEntitySuccess(state, { payload }) { },
    createLegalEntityFailure(state, { payload }) { },
    getLegalEntityStart(state, { payload }) {
      state.loading = true;
    },
    getLegalEntitySuccess(state, { payload }) {
      const { response } = payload;
      state.legalEntity = response;
      state.loading = false;
    },
    getLegalEntityFailure(state, { payload }) { },
    editLegalEntityStart(state, { payload }) {
      state.loading = true;
    },
    editLegalEntitySuccess(state, { payload }) {
      const { response } = payload;
      state.loading = false;
      state.legalEntity = response;
    },
    editLegalEntityFailure(state, { payload }) { },

  },
});

export const { actions } = slice;

export default slice.reducer;
