import _ from 'lodash';
import moment from 'moment';
import { REPAYMENT_SOURCES, REPAYMENT_TYPES } from 'src/constants';

const parseToLocalTimezone = (dateStr) => moment(dateStr).local();
const formatDate = (dateStr, format) => (typeof dateStr === 'object' ? parseToLocalTimezone(dateStr).format(format) : dateStr && dateStr.split('.').reverse().join('-'));
export const isBase64 = (string: string) => string && string.startsWith('data:');

export const formatDateByKey = (key, format = 'YYYY-MM-DD') =>
  (data) => {
    const value = data[key];
    const formattedValue = formatDate(value, format);
    if (value && !(formattedValue === 'Invalid date')) {
      return {
        ...data,
        [key]: formattedValue,
      };
    }
    return _.omit(data, key);
  };

export const formatTimeByKey = (key, format = 'HH:mm') =>
  (data) => {
    const value = data[key];
    const formattedValue = formatDate(value, format);
    if (value && !(formattedValue === 'Invalid date')) {
      return {
        ...data,
        [key]: formattedValue,
      };
    }
    return _.omit(data, key);
  };

export const joinParamByKey = (key) => (data) => {
  if (!_.isEmpty(data[key])) {
    const ids = [];
    data[key].map((item) => ids.push(item.id));
    return {
      ...data,
      [key]: ids.join(','),
    };
  }
  return _.omit(data, key);
};

export const setIdByKey = (key) => (data) => {
  if (data[key]) {
    return {
      ...data,
      [key]: _.get(data, `${key}.id`, data[key]),
    };
  }
  return data;
};
export const setAutocompleteObjectByKey = (key, displayName = 'name') => (data) => {
  if (data[key]) {
    return {
      ...data,
      [key]: {
        id: _.get(data, `${key}.id`, data[key]),
        name: String(_.get(data, `${key}.${displayName}`)),
      },
    };
  }
  return data;
};

export const removeByKey = (key) => (data) => {
  if (data[key]) {
    return _.omit(data, [key]);
  }
  return data;
};

export const setShipments = (key: string) => (data) => {
  if (data[key]) {
    return {
      ...data,
      [key]: data[key].map((item) =>
        ({ nomenclature: item.nomenclature.id, amount: Number(item.amount) })),
    };
  }
  return data;
};

export const setFormShipments = (key: string) => (data) => {
  if (data[key]) {
    return {
      ...data,
      [key]: data[key].map((item) =>
        ({
          nomenclature: {
            name: item.nomenclature.name,
            id: item.nomenclature.id,
            unit: item.nomenclature.unit,
          },
          amount: `${Number(item.amount)}`,
        })),
    };
  }
  return data;
};

export const setEndDate = (key) => (data) => {
  if (data[key]) {
    return {
      ...data,
      [key]: formatDate(data[key], 'YYYY-MM-DD'),
    };
  }
  if (!data[key] && data.pickup_start_date) {
    return { ...data, pickup_end_date: formatDate(data.pickup_start_date, 'YYYY-MM-DD') };
  }
  return data;
};

export const setStatusByKey = (key: string) => (data) => {
  if (data[key]) {
    return {
      ...data,
      [key]: data[key] === 'true',
    };
  }
  return data;
};

export const setOtherByKey = (key: string) => (data) => {
  if (data[key]) {
    return {
      ...data,
      unit: `Другое - ${data[key]}`,
    };
  }
  return data;
};

export const getNameByKey = (key: string) => (data) => {
  if (data[key]) {
    return {
      ...data,
      [key]: data[key].map((item) => item.name).join(','),
    };
  }
  return data;
};

export const getNameFromCityByKey = (key: string) => (data) => {
  if (data[key]) {
    return {
      ...data,
      [key]: {
        ...data[key],
        city: data[key].city.id,
      },
    };
  }
  return data;
};

export const getStatusIsActive = (key: string) => (data) => {
  if (data[key]) {
    return {
      ...data,
      [key]: !!data[key].id,
    };
  }
  return _.omit(data, [key]);
};

export const getAddressByKey = (key: string) => (data) => {
  if (data[key]) {
    return {
      ...data,
      [key]: {
        country: data[key].addressObject.country,
        region_name: data[key].addressObject.region_name,
        city_name: data[key].addressObject.city_name,
        street: data[key].addressObject.street && data[key].addressObject.street.replace('улица', ''),
        building_number: data[key].addressObject.building_number,
        location: data[key].addressObject.location,
        extra_data: data[key].extra_data,
      },
    };
  }
  return data;
};

export const getNumberPhoneByKey = (key: string) => (data) => {
  if (data[key]) {
    return {
      ...data,
      [key]: data[key].replace(/[^0-9]/g, ''),
    };
  }
  return data;
};

export const setOtherValue = (key: string) => (data) => {
  if (data[key]) {
    return {
      ...data,
      unit: data[key].split(' ').shift() === 'Другое' ? data[key].split(' ').shift() : data[key],
      other: data[key].split(' ').shift() === 'Другое' ? data[key].split(' ').pop() : '',
    };
  }
  return data;
};

export const setTimeObject = (key, format = 'HH:mm') => (data) => {
  if (data[key]) {
    return {
      ...data,
      [key]: moment(data[key], format),
    };
  }
  return data;
};

export const getReturnID = (key: string) => (data) => {
  if (data[key]) {
    return {
      ...data,
      [key]: data[key].id,
    };
  }
  return data;
};

export const getNumberTypeByKey = (key: string) => (data) => {
  if (data[key]) {
    const obj = [];
    data[key].map((item) => obj.push(Number(item)));
    return {
      ...data,
      [key]: obj,
    };
  }
  return data;
};

export const setString = (key: string) => (data) => {
  if (data[key]) {
    return {
      ...data,
      [key]: String(data[key]),
    };
  }
  return data;
};

export const setNumber = (key: string) => (data) => {
  if (data[key]) {
    return {
      ...data,
      [key]: Number(data[key]),
    };
  }
  return data;
};

export const removeIfNotBase64 = (key) => (data) => {
  if (data[key] && isBase64(data[key])) {
    return {
      ...data,
      [key]: data[key],
    };
  }
  return _.omit(data, [key]);
};

export const setValueByKey = (key: string) => (data: Record<string, number | string>) => {
  if (data[key]) {
    return {
      ...data,
      [key]: data[key],
    };
  }
  return _.omit(data, [key]);
};

export const setIsCash = (key: string) => (data) => {
  if (Number(data.source) === REPAYMENT_SOURCES.MANAGER) {
    return {
      ...data,
      [key]: true,
    };
  }
  if (data[key]) {
    return {
      ...data,
      [key]: data[key],
    };
  }
  return {
    ...data,
    [key]: false,
  };
};

export const setPaymentStatus = (key: string) => (data) => {
  if (data[key] && data.source !== 6) {
    return {
      ...data,
      [key]: 3,
    };
  }
  return {
    ...data,
    [key]: 6,
  };
};

export const setRepaymentType = (key: string) => (data) => ({
  ...data,
  type: REPAYMENT_TYPES[key],
});

export const getPaymentStatus = (key: string) => (data) => {
  if (data[key] && data[key] !== 6) {
    return {
      ...data,
      [key]: true,
    };
  }
  return {
    ...data,
    [key]: false,
  };
};
