import { createSlice } from '@reduxjs/toolkit';
import { merge, reduce } from 'lodash';

import { ENTITY_TYPES } from 'src/constants';

const initialState = reduce(
  ENTITY_TYPES,
  (result) => {
    result = merge(
      {
        deal: null,
        loading: false,
      },
    );
    return result;
  },
  {},
);

const slice = createSlice({
  name: 'deal',
  initialState,
  reducers: {
    createDealStart(state, { payload }) { },
    createDealSuccess(state, { payload }) { },
    createDealFailure(state, { payload }) { },
    getDealStart(state, { payload }) {
      state.loading = true;
    },
    getDealSuccess(state, { payload }) {
      const { response } = payload;
      state.deal = response;
      state.loading = false;
    },
    getDealFailure(state, { payload }) { },
    editDealStart(state, { payload }) {
      state.loading = true;
    },
    editDealSuccess(state, { payload }) {
      const { response } = payload;
      state.loading = false;
      state.deal = response;
    },
    editDealFailure(state, { payload }) { },
  },
});

export const { actions } = slice;

export default slice.reducer;
