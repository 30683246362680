import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { MainContainer } from 'src/components/Layout';
import Sidebar from 'src/components/Sidebar';
import Footer from 'src/components/Mobile/TabBar';

import { useAuth } from 'src/contexts/auth';
import useSidebarOpen from 'src/hooks/useSidebarOpen';

const Main = ({ children, isMobile }) => {
  const { isSidebarOpen, toggleSidebarOpen } = useSidebarOpen();

  const { authData } = useAuth();
  const router = useRouter();
  const { is_authenticated: isAuthenticated } = authData;
  const [authorized, setAuthorized] = useState(false);
  const [isLoginPage, setIsLoginPage] = useState(true);

  function authCheck(url) {
    const publicPaths = ['/login'];
    const path = url.split('?')[0];
    if (!isAuthenticated && !publicPaths.includes(path)) {
      setAuthorized(false);
      router.push({
        pathname: '/login',
      });
    } else {
      setAuthorized(true);
    }
  }

  useEffect(() => {
    authCheck(router.asPath);
    const hideContent = () => setAuthorized(false);
    router.events.on('routeChangeStart', hideContent);
    router.events.on('routeChangeComplete', authCheck);
    return () => {
      router.events.off('routeChangeStart', hideContent);
      router.events.off('routeChangeComplete', authCheck);
    };
  }, [authData]);

  useEffect(() => {
    if (router.pathname !== '/login' && isAuthenticated) {
      setIsLoginPage(false);
    } else {
      setIsLoginPage(true);
    }
  }, [router]);

  return (
    <MainContainer open={isSidebarOpen} isMobile={isMobile}>
      {!isLoginPage && !isMobile && (
        <Sidebar
          open={isSidebarOpen}
          setOpen={toggleSidebarOpen}
        />
      )}
      {authorized && children}
      {isMobile && !isLoginPage && (
        <Footer open={isSidebarOpen} />
      )}
    </MainContainer>
  );
};

export default Main;
