import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';

import config from 'config';

interface ErrorResponse {
  json: Record<string, string>,
  throwError: boolean
}
const HTTP_INTERNAL_SERVER_ERROR_CODE = 500;

const checkStatus = (response) => {
  if (response.ok) return response;

  if (response.status >= HTTP_INTERNAL_SERVER_ERROR_CODE) {
    return {
      throwError: true,
      json: {
        detail:
          'Ошибка на сервере! Если ошибка не исчезнет в ближайшее время - обратитесь к администратору',
      },
    };
  }

  return response.json().then((json) => ({
    json,
    throwError: true,
  }));
};

const checkException = (response: ErrorResponse) => {
  if (response.throwError === true) {
    const errorMessage: string = Object.keys(response.json).map((key: string) => response.json[key]).join('. ');
    throw new Error(errorMessage);
  }
  return response;
};

const getCookie = (name) => {
  const matches = document.cookie.match(
    // eslint-disable-next-line no-useless-escape
    new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`),
  );
  return matches ? decodeURIComponent(matches[1]) : '';
};

const isFormDataRequest = (data) => data instanceof FormData;

const parseSettings = ({
  method, data, headers, ...otherParams
}: any = {}) => {
  const isFormData = isFormDataRequest(data);
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-CSRFToken': getCookie('csrftoken'),
  };

  // Удаляем content-type, что бы браузер автоматически его подобрал
  if (isFormData) delete defaultHeaders['Content-Type'];

  return {
    headers: {
      ...defaultHeaders,
      ...headers,
    },
    method,
    body: isFormData ? data : JSON.stringify(data),
    withCredentials: true,
    credentials: 'include',
    ...otherParams,
  };
};

const parseEndpoint = (path, qs) => {
  const query = qs ? `?${queryString.stringify(qs)}` : '';
  return `${config.apiUrl}${path}${query}`;
};

const contentTypeResponseMapping = {
  'application/zip': true,
  'application/ms-excel': true,
};

const parseJSON = (response) => {
  const contentType = response.headers.get('content-type');
  if (isEmpty(contentType)) return response;
  if (contentTypeResponseMapping[contentType]) return response;
  return response.json();
};

const request = (path, { qs = undefined, ...params } = {}) =>
  fetch(parseEndpoint(path, qs), parseSettings(params))
    .then(checkStatus)
    .then(checkException)
    .then(parseJSON);

const api = {
  post(path, params) {
    return request(path, { method: 'POST', ...params });
  },

  get(path, params) {
    return request(path, { method: 'GET', ...params });
  },

  put(path, params) {
    return request(path, { method: 'PUT', ...params });
  },

  patch(path, params) {
    return request(path, { method: 'PATCH', ...params });
  },

  delete(path, params) {
    return request(path, { method: 'DELETE', ...params });
  },
};

export default api;
