import flow from 'lodash/flow';
import {
  formatDateByKey,
  joinParamByKey,
  getNameByKey,
  setValueByKey,
  getStatusIsActive,
  setIdByKey,
} from './utils';

export const parseBidsFilterParams = (data) => {
  const parseParams = flow([
    joinParamByKey('seller_id__in'),
    joinParamByKey('manager_id__in'),
    joinParamByKey('shipments__nomenclature_id__in'),
    joinParamByKey('status__in'),
    formatDateByKey('created__gte'),
    formatDateByKey('created__lte'),
    formatDateByKey('pickup_start_date__gte'),
    formatDateByKey('pickup_start_date__lte'),
  ]);
  return parseParams(data);
};

export const parseDealsFilterParams = (data) => {
  const parseParams = flow([
    joinParamByKey('seller_id__in'),
    joinParamByKey('trading_manager_id__in'),
    joinParamByKey('shipments__nomenclature_id__in'),
    joinParamByKey('status__in'),
    joinParamByKey('buyer_id__in'),
    formatDateByKey('created__gte'),
    formatDateByKey('created__lte'),
    formatDateByKey('plane_machine_date__gte'),
    formatDateByKey('plane_machine_date__lte'),
    setValueByKey('id'),
  ]);
  return parseParams(data);
};
export const parseNomenclaturesFilterParams = (data) => {
  const parseParams = flow([
    setValueByKey('id'),
    getNameByKey('name__in'),
    getStatusIsActive('is_active'),
  ]);
  return parseParams(data);
};
export const parseTransportCompaniesFilterParams = (data) => {
  const parseParams = flow([
    setValueByKey('id'),
    setValueByKey('name__icontains'),
    getStatusIsActive('is_active'),
  ]);
  return parseParams(data);
};

export const parseContractorsFilterParams = (data) => {
  const parseParams = flow([
    setValueByKey('id'),
    setValueByKey('name__icontains'),
    joinParamByKey('company_type__in'),
    joinParamByKey('nomenclature_type__contains'),
    getStatusIsActive('is_active'),
  ]);
  return parseParams(data);
};
export const parseCashDepositFilterParams = (data) => {
  const parseParams = flow([
    formatDateByKey('created__gte'),
    formatDateByKey('created__lte'),
    joinParamByKey('wallet__owner_id__in'),
    joinParamByKey('status__in'),
  ]);
  return parseParams(data);
};

export const parseRequestFilterParams = (data) => {
  const parseParams = flow([
    formatDateByKey('created__gte'),
    formatDateByKey('created__lte'),
    joinParamByKey('wallet__owner_id__in'),
    joinParamByKey('status__in'),
  ]);
  return parseParams(data);
};

export const parseExpectedRepaymentFilterParams = (data) => {
  const parseParams = flow([
    joinParamByKey('deal__trading_manager__id__in'),
    joinParamByKey('deal__buyer_id__in'),
    joinParamByKey('deal__seller_id__in'),
    setIdByKey('is_cash'),
    setIdByKey('source'),
    setIdByKey('status'),
  ]);
  return parseParams(data);
};

export const parseExpectedIncomesFilterParams = (data) => {
  const parseParams = flow([
    joinParamByKey('deal__buyer_id__in'),
  ]);
  return parseParams(data);
};
