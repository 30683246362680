import { all, fork } from 'redux-saga/effects';
import transportCompanies from './transportCompanies/sagas';
import entitySagas from './entity/sagas';
import bidSagas from './bid/sagas';
import nomenclaturesSagas from './nomenclatures/sagas';
import dealSagas from './deal/sagas';
import dealShipmentSagas from './dealShipment/sagas';

import contractors from './contractors/sagas';
import legalEntity from './legalEntities/sagas';
import platform from './platform/sagas';
import snackbar from '../lib/snackbar';
import expectedRepaymentSagas from './expectedRepayments/sagas';
import actualRepaymentsSagas from './actualRepayments/sagas';
import walletSagas from './wallet/sagas';
import actualIncomesSagas from './actualIncomes/sagas';
import expectedIncomesSagas from './expectedIncomes/sagas';
import cashRequestSagas from './cashRequest/sagas';
import cashDepositSagas from './cashDeposit/sagas';
import managerSagas from './manager/sagas';

const sagas: any = [
  entitySagas,
  bidSagas,
  nomenclaturesSagas,
  dealSagas,
  transportCompanies,
  snackbar,
  contractors,
  legalEntity,
  platform,
  dealShipmentSagas,
  expectedRepaymentSagas,
  actualRepaymentsSagas,
  walletSagas,
  expectedIncomesSagas,
  actualIncomesSagas,
  cashRequestSagas,
  cashDepositSagas,
  managerSagas,
];

export default function* allSagas(services = {}) {
  yield all(sagas.map((saga) => fork(saga, services)));
}
