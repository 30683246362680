/* eslint-disable import/no-import-module-exports */
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { middleware as thunkMiddleware } from 'redux-saga-thunk';

import config from '../../config';
import rootReducer from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunkMiddleware, sagaMiddleware];
const { isDev, isBrowser } = config;

if (isDev && isBrowser) {
  const logger = createLogger({
    level: 'info',
    duration: true,
    timestamp: true,
    collapsed: true,
  });
  middlewares.push(logger);
}

const makeStore = (preloadedState) => {
  const store = configureStore({
    reducer: rootReducer,
    devTools: isDev && isBrowser,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ thunk: false, serializableCheck: false, immutableCheck: false })
        .concat(middlewares),
    preloadedState,
  });
  let sagaTask = sagaMiddleware.run(sagas);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(rootReducer);
    });
    module.hot.accept('./sagas', () => {
      // eslint-disable-next-line global-require
      const nextSagas = require('./sagas').default;
      sagaTask.cancel();
      sagaTask.toPromise().then(() => {
        sagaTask = sagaMiddleware.run(nextSagas);
      });
    });
  }

  return store;
};

export default makeStore;
