import { createSlice } from '@reduxjs/toolkit';
import { merge, reduce } from 'lodash';

import { ENTITY_TYPES } from 'src/constants';

const initialState = reduce(
  ENTITY_TYPES,
  (result) => {
    result = merge(
      {
        transportCompanies: null,
        loading: false,
      },
    );
    return result;
  },
  {},
);

const slice = createSlice({
  name: 'transportCompanies',
  initialState,
  reducers: {
    createTransportCompaniesStart(state, { payload }) { },
    createTransportCompaniesSuccess(state, { payload }) { },
    createTransportCompaniesFailure(state, { payload }) { },
    getTransportCompaniesStart(state, { payload }) {
      state.loading = true;
    },
    getTransportCompaniesSuccess(state, { payload }) {
      const { response } = payload;
      state.transportCompanies = response;
      state.loading = false;
    },
    getTransportCompaniesFailure(state, { payload }) { },
    editTransportCompaniesStart(state, { payload }) {
      state.loading = true;
    },
    editTransportCompaniesSuccess(state, { payload }) {
      const { response } = payload;
      state.loading = false;
      state.TransportCompanies = response;
    },
    editTransportCompaniesFailure(state, { payload }) { },

  },
});

export const { actions } = slice;

export default slice.reducer;
