import {
  flow,
  isEmpty,
  transform,
  isEqual,
  get,
} from 'lodash';
import { Shipment, Nomenclature } from 'src/interfaces';
import { Amount } from 'src/components/Layout';
import {
  setAutocompleteObjectByKey,
  formatDateByKey,
  removeByKey,
  setEndDate,
  setFormShipments,
  setIdByKey,
  setOtherValue,
  setTimeObject,
  setString, setValueByKey, getPaymentStatus,
} from './parser/utils';

export const getNomenclaturesFromShipments = (shipments: Shipment[]) => {
  const nomenclatures: string[] = shipments?.reduce((
    acc: string[],
    shipment: {
      nomenclature: Nomenclature
    },
  ) => {
    acc.push(shipment.nomenclature.name);
    return acc;
  }, []);
  return nomenclatures;
};

export const parseBidData = flow([
  formatDateByKey('pickup_start_date'),
  setEndDate('pickup_end_date'),
  setAutocompleteObjectByKey('seller'),
  setAutocompleteObjectByKey('manager', 'full_name'),
  setIdByKey('cancel_reason'),
  setFormShipments('shipments'),
  removeByKey('status'),
]);

export const parseNomenclatureData = flow([
  setOtherValue('unit'),
]);

export const parsePaymentData = flow([
  setAutocompleteObjectByKey('wallet', 'owner.full_name'),
  getPaymentStatus('status'),
]);

export const checkIsFilterValuesEmpty = (values) => {
  let isFilterValuesEmpty = true;
  Object.keys(values).forEach((key: string) => {
    if (!isEmpty(values[key])) {
      isFilterValuesEmpty = false;
    }
  });
  return isFilterValuesEmpty;
};

export const parseDealFormInitialValues = flow([
  setAutocompleteObjectByKey('bid', 'id'),
  setAutocompleteObjectByKey('trading_manager', 'full_name'),
  setAutocompleteObjectByKey('sales_manager', 'full_name'),
  setAutocompleteObjectByKey('seller_platform', 'address.full_address'),
  setAutocompleteObjectByKey('seller_entity'),
  setAutocompleteObjectByKey('buyer_platform', 'address.full_address'),
  setAutocompleteObjectByKey('buyer_entity'),
  setAutocompleteObjectByKey('transport_company'),
  setTimeObject('plane_machine_start_time'),
  setTimeObject('plane_machine_end_time'),
  setTimeObject('loading_start_time'),
  setTimeObject('loading_finish_time'),
  setTimeObject('fact_machine_time'),
  setValueByKey('is_document_copy_received'),
  setValueByKey('is_document_scan_transferred'),
  setValueByKey('is_document_original_received'),
  setValueByKey('is_document_onec_uploaded'),
  setString('delivery_responsibility'),
]);

export const diff = (base, set) =>
  transform(set, (res, value, key) => {
    if (!isEqual(value, base[key])) {
      res[key] = value;
    }
  });

export const removeCommaAtTheEnd = (str: string) => {
  if (str[str.length - 2] === ',') {
    const indexOf = str.lastIndexOf(',');
    return str.substring(0, indexOf);
  }
  return str;
};

export const getDocumentName = (url: string) => {
  if (url) {
    return url.split('/').pop();
  }
  return null;
};

export const convertorUnit = (unit) => {
  if (unit) {
    if (unit.split(' ').shift() === 'Другое') {
      return unit.split(' ').pop();
    }
    switch (unit) {
      case 'Тонна':
        return 'т';
      case 'килограмм':
        return 'кг';
      case 'Штука':
        return 'шт';
      default:
        return 'кг';
    }
  } else {
    return 'кг';
  }
};

export const getAmountSum = (remainder, paid, isMobile = false) => {
  const amount = !!remainder?.length
    && remainder?.reduce((accumulator, currentValue) => {
      if (currentValue?.status) {
        if (currentValue?.status === 'Оплачено') {
          return Number(accumulator) + Number(currentValue.amount);
        }
      } else {
        return Number(accumulator) + Number(currentValue.amount);
      }
      return accumulator;
    }, '');
  return (
    <>
      <Amount sx={{ marginRight: '10px' }} isMobile={isMobile}>{`Оплачено: ${amount || 0} ₽`} </Amount>
      <Amount isMobile={isMobile} isDebt> Остаток: {paid - amount || 0} ₽ </Amount>
    </>
  );
};

export const getSum = (data, key) =>
  data && data.reduce((acc, elem) => {
    if (elem.status) {
      if (elem.status === 'Оплачено') {
        return Number(acc) + parseFloat(elem[key]);
      }
    } else {
      return Number(acc) + parseFloat(elem[key]);
    }

    return acc;
  }, '');

const buildAutocompleteList = (data, displayName, displayNameAdditionalInfo) => {
  const list = data.map((item) => ({
    ...item,
    name: displayNameAdditionalInfo
      ? `${String(get(item, displayName))}, ${String(get(item, displayNameAdditionalInfo))}`
      : String(get(item, displayName)),
    id: item.id,
  }));
  return list;
};

export const getAutocompleteList = async (
  params: Record<string, string | number>,
  getRequest,
  setDataList,
  displayName: string,
  displayNameAdditionalInfo?: string,
) => {
  if (getRequest) {
    const data = await getRequest({ ...params, page_size: 400 });
    if (data?.results) {
      const list = buildAutocompleteList(data.results, displayName, displayNameAdditionalInfo);
      setDataList(list);
    }
  }
};
