import {
  useContext,
  createContext,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { UserInterface } from 'src/interfaces';

interface AuthDataInterface {
  user: UserInterface | null
  is_authenticated: boolean
}
interface AuthContextInterface {
  authData: AuthDataInterface,
  updateAuthData: (data: AuthDataInterface) => void,
}

const AuthContext = createContext<AuthContextInterface>({
  authData: {
    user: null,
    is_authenticated: false,
  },
  updateAuthData: () => null,
});

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

const AuthProvider = ({ children, initialState }) => {
  const [authData, setAuthData] = useState(initialState);

  const updateAuthData = useCallback((data) => {
    setAuthData(data);
  }, [setAuthData]);

  const value = useMemo(
    () => ({
      authData,
      updateAuthData,
    }),
    [authData, updateAuthData],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthProvider, useAuth };
