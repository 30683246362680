import get from 'lodash/get';
import { ENTITY_TYPES } from 'src/constants';

export const ENTITY_PROPS = {
  [ENTITY_TYPES.BID_ENTITY]: {
    title: 'Заявка',
    label: 'Заявки',
    createLabel: 'Создать заявку',
    endpoint: 'deals/bids/',
  },
  [ENTITY_TYPES.DEAL_ENTITY]: {
    title: 'Сделка',
    label: 'Сделки',
    createLabel: 'Создать сделку',
    endpoint: 'deals/deals/',
  },
  [ENTITY_TYPES.NOMENCLATURE_ENTITY]: {
    title: 'Справочники. Номенклатуры',
    label: 'Номенклатуры',
    createLabel: 'Создать номенклатуру',
    endpoint: 'deals/nomenclatures/',
  },
  [ENTITY_TYPES.CONTRACTORS_ENTITY]: {
    title: 'Справочники. Контрагенты',
    label: 'Контрагенты',
    createLabel: 'Создать Контрагента',
    endpoint: 'deals/contractors/',
  },
  [ENTITY_TYPES.EXPECTED_REPAYMENT_ENTITY]: {
    endpoint: 'billing/expected_repayments/',
  },
  [ENTITY_TYPES.EXPECTED_INCOME_ENTITY]: {
    endpoint: 'billing/expected_repayments/',
  },
  [ENTITY_TYPES.ACTUAL_REPAYMENT_ENTITY]: {
    endpoint: 'billing/repayments/',
  },
  [ENTITY_TYPES.ACTUAL_INCOME_ENTITY]: {
    endpoint: 'billing/repayments/',
  },
  [ENTITY_TYPES.PLATFORM_ENTITY]: {
    endpoint: 'deals/platforms/',
  },
  [ENTITY_TYPES.TRANSPORT_COMPANIES_ENTITY]: {
    title: 'Справочники. Транспортные компании',
    label: 'Транспортные компании',
    createLabel: 'Создать транспортную компанию',
    endpoint: 'deals/transport_companies/',
  },
  [ENTITY_TYPES.DEAL_SHIPMENT_ENTITY]: {
    endpoint: 'deals/shipments/',
  },
  [ENTITY_TYPES.LEGAL_ENTITY]: {
    endpoint: 'deals/entities/',
  },

  [ENTITY_TYPES.CASH_REQUEST_ENTITY]: {
    endpoint: 'billing/cash_requests/',
  },
  [ENTITY_TYPES.CASH_DEPOSIT_ENTITY]: {
    endpoint: 'billing/cash_deposits/',
  },
  [ENTITY_TYPES.MANAGER_ENTITY]: {
    endpoint: 'billing/wallets/',
  },
};

const getEntityProps = (path, defaultValue = {}) => get(ENTITY_PROPS, path, defaultValue);

export default getEntityProps;
