import { createSlice } from '@reduxjs/toolkit';
import { merge, reduce } from 'lodash';

import { ENTITY_TYPES } from 'src/constants';

const initialState = reduce(
  ENTITY_TYPES,
  (result) => {
    result = merge(
      {
        contractors: null,
        loading: false,
      },
    );
    return result;
  },
  {},
);

const slice = createSlice({
  name: 'contractors',
  initialState,
  reducers: {
    createContractorsStart(state, { payload }) { },
    createContractorsSuccess(state, { payload }) { },
    createContractorsFailure(state, { payload }) { },
    getContractorsStart(state, { payload }) {
      state.loading = true;
    },
    getContractorsSuccess(state, { payload }) {
      const { response } = payload;
      state.contractors = response;
      state.loading = false;
    },
    getContractorsFailure(state, { payload }) { },
    editContractorsStart(state, { payload }) {
      state.loading = true;
    },
    editContractorsSuccess(state, { payload }) {
      const { response } = payload;
      state.loading = false;
      state.contractors = response;
    },
    editContractorsFailure(state, { payload }) { },

  },
});

export const { actions } = slice;

export default slice.reducer;
