import { createSlice } from '@reduxjs/toolkit';
import { merge, reduce } from 'lodash';
import parseListResponse from 'src/lib/parser/listResponse';
import { ENTITY_TYPES } from 'src/constants';

const initialState = reduce(
  ENTITY_TYPES,
  (result, entityType) => {
    result[entityType] = merge(
      {
        list: [],
        currentData: {},
        isLoadingCurrentData: false,
        isCreateMode: false,
        isFormDirty: false,
        requestObjectId: null,
        isOpenOtherObjectDialog: false,
        tableOptions: {},
        filterValues: {},
        nextListUrl: null,
        isLoadingMore: false,
        isLoadingList: false,
        modalWindowsState: {
          isCreateOpen: false,
          isDetailsOpen: false,
          isEditOpen: false,
          isCancelReasonOpen: false,
          collapsedDataId: false,
          entityType,
        },
      },
    );
    return result;
  },
  {},
);

const slice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    getListStart(state, { payload }) {
      const { entityType, values } = payload;
      if (values) {
        state[entityType].filterValues = values;
      }
      state[entityType].isLoadingList = true;
    },
    getListSuccess(state, { payload }) {
      const { response, entityType } = payload;
      const { next, results } = response;
      state[entityType].list = parseListResponse({ results, entityType });
      state[entityType].nextListUrl = next;
      state[entityType].isLoadingList = false;
    },
    getListFailure(state, { payload }) {
      const { entityType } = payload;
      state[entityType].isLoadingList = false;
    },
    setFilterValues(state, { payload }) {
      const { entityType, values } = payload;
      state[entityType].filterValues = values;
    },
    loadOrderMoreListStart(state, { payload }) {
      state.isLoadingMore = true;
    },
    loadOrderMoreListSuccess(state, { payload }) {
      const { response, entityType } = payload;
      const { next, results } = response;
      state[entityType].isLoadingMore = false;
      state[entityType].nextListUrl = next;
      state[entityType].list = [...state[entityType].list, ...results];
    },
    loadOrderMoreListFailure(state) {
      state.isLoadingMore = false;
    },
    setModalWindowsState(state, { payload }) {
      const { entityType } = payload;
      state[entityType].modalWindowsState = payload;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
