import {
  put, takeEvery, call,
} from 'redux-saga/effects';

import api from 'src/lib/api';
import getEntityProps from 'src/entities/props';
import { actions as snackbar } from 'src/store/snackbar/slice';
import { actions as entityActions } from 'src/store/entity/slice';

import getResponseErrorMessage from 'src/lib/utils';
import getSnackbarMessage from 'src/lib/snackbar';
import { ENTITY_TYPES } from 'src/constants';
import { actions } from './slice';
import parseRequest from '../../lib/parser/request';

const entityType = ENTITY_TYPES.CASH_REQUEST_ENTITY;
const parseRequestData = (data) => parseRequest({ entityType, data });

function* createCashRequest({ payload }) {
  try {
    const endpoint = getEntityProps([entityType, 'endpoint']);
    const response = yield call(api.post, endpoint, { data: parseRequestData(payload) });
    yield put(snackbar.openSuccessSnackbar(`Запрос денег ${response.id} успешно создан`));
    yield put(entityActions.getListStart({ entityType, values: {} }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

function* getCashRequest({ payload }) {
  const { id } = payload;
  try {
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}${id}/`;
    const response = yield call(api.get, endpoint, {});
    yield put(actions.getCashRequestSuccess({ response }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

function* editCashRequest({ payload }) {
  const { id } = payload;
  try {
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}${id}`;
    const response = yield call(api.patch, endpoint, { data: parseRequestData(payload) });
    yield put(actions.editCashRequestSuccess({ response }));
    yield put(snackbar.openSuccessSnackbar(`Запрос денег ${response.id} успешно обновлено`));
    yield put(entityActions.getListStart({ entityType, values: {} }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}
function* editStatusPaidCashRequest({ payload }) {
  const { id } = payload;
  try {
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}${id}/pay/`;
    const response = yield call(api.patch, endpoint, { data: parseRequestData(payload) });
    yield put(actions.editCashRequestSuccess({ response }));
    yield put(snackbar.openSuccessSnackbar(`Запрос денег ${response.id} успешно обновлено`));
    yield put(entityActions.getListStart({ entityType, values: {} }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}
function* editStatusRequestedCashRequest({ payload }) {
  const { id } = payload;
  try {
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}${id}/`;
    const response = yield call(api.patch, endpoint, { data: parseRequestData(payload) });
    yield put(actions.editCashRequestSuccess({ response }));
    yield put(snackbar.openSuccessSnackbar(`Запрос денег ${response.id} успешно обновлено`));
    yield put(entityActions.getListStart({ entityType, values: {} }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}
function* editCashRequestAccountantComment({ payload }) {
  const { id } = payload;
  try {
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}${id}/`;
    const response = yield call(api.patch, endpoint, { data: parseRequestData(payload) });
    yield put(actions.editCashRequestSuccess({ response }));
    yield put(snackbar.openSuccessSnackbar(`Комментарий бухгалтера ${response.id} успешно сохранены`));
    yield put(entityActions.getListStart({ entityType, values: {} }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

export default function* sagas() {
  yield takeEvery(actions.createCashRequestStart, createCashRequest);
  yield takeEvery(actions.getCashRequestStart, getCashRequest);
  yield takeEvery(actions.editCashRequestStart, editCashRequest);
  yield takeEvery(actions.editStatusPaidCashRequestStart, editStatusPaidCashRequest);
  yield takeEvery(actions.editStatusRequestedCashRequestStart, editStatusRequestedCashRequest);
  yield takeEvery(actions.editCashRequestAccountantCommentStart, editCashRequestAccountantComment);
}
