import {
  put, takeEvery, select, call,
} from 'redux-saga/effects';

import api from 'src/lib/api';
import getEntityProps from 'src/entities/props';
import { actions as snackbar } from 'src/store/snackbar/slice';

import parseFilterParams from 'src/lib/parser/filter';

import selectFilterValues from 'src/lib/store/selector';
import getResponseErrorMessage from 'src/lib/utils';
import getSnackbarMessage from 'src/lib/snackbar';
import { actions } from './slice';

function* getParams({ entityType, values }) {
  const filterValues = yield select(
    selectFilterValues(entityType),
  );
  const data = values || filterValues;
  return parseFilterParams({ entityType, data });
}

function* getList({ payload }) {
  const { entityType } = payload;
  try {
    const endpoint = getEntityProps([entityType, 'endpoint']);
    const params = yield* getParams(payload);
    const response = yield call(api.get, endpoint, { qs: params });
    yield put(actions.getListSuccess({ entityType, response }));
  } catch (e) {
    yield put(actions.getListFailure({ entityType, error: e }));
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

function* loadMoreList({ payload }) {
  const { entityType, url } = payload;
  try {
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}${url}`;
    const response = yield call(api.get, endpoint, `${url}`);
    yield put(actions.loadOrderMoreListSuccess({ entityType, response }));
  } catch (e) {
    yield put(actions.loadOrderMoreListFailure());
  }
}

export default function* sagas() {
  yield takeEvery(actions.getListStart, getList);
  yield takeEvery(actions.loadOrderMoreListStart, loadMoreList);
}
