export const BREAKPOINTS = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopM: '1366px',
  laptopL: '1440px',
  desktop: '1600px',
  desktopM: '1920px',
  desktopL: '2560px',
};

export const BID_STATUSES = {
  new: 3,
  inWork: 6,
  canceled: 9,
  dealCreated: 12,
};

export const CREATE_BID_FORM_RADIO_BUTTONS = [
  { label: 'Фиксированная дата', value: 'fixed' },
  { label: 'Интервал дат', value: 'interval' },
];

export const CREATE_NOMENCLATURE_MEASUREMENT_UNITS_FORM_RADIO_BUTTONS = [
  { label: 'Тонна', value: 'Тонна' },
  { label: 'Килограмм', value: 'Килограмм' },
  { label: 'Штука', value: 'Штука' },
  { label: 'Другое', value: 'Другое' },
];

export const CREATE_LOADING_TYPE_FORM_CHECKBOX = [
  { label: 'Боковая', value: '3' },
  { label: 'Задняя', value: '6' },
  { label: 'Другое', value: '0' },
];

export const TAXATION_SYSTEM_FORM_RADIO_BUTTONS2 = [
  { label: 'ОСНО', value: 3 },
  { label: 'УСН', value: 6 },
  { label: 'Другое', value: 0 },

];

export const STATUS_FORM_RADIO_BUTTONS = [
  { label: 'Активен', value: 'true' },
  { label: 'Не активен', value: 'false' },
];

export const REPAYMENT_EXPENSE_TYPE_OPTIONS = [
  { label: 'Оплата поставщику', value: 3 },
  { label: 'Оплата транспортной компании', value: 6 },
  { label: 'Оплата комиссии', value: 9 },
  { label: 'Дополнительные расходы', value: 12 },
];

export const REPAYMENT_SOURCE_OPTIONS = [
  { label: 'Бухгалтерия', value: 6 },
  { label: 'Кошелёк менеджера', value: 3 },
];

export const REPAYMENT_SOURCES = {
  ACCOUNTANT: 6,
  MANAGER: 3,
};

export const REPAYMENT_IS_CASH_OPTIONS = [
  { label: 'Наличные', value: true },
  { label: 'Безнал', value: false },
];

export const REPAYMENT_TYPES = {
  income: 3,
  expense: 6,
};

export const ENTITY_TYPES = {
  BID_ENTITY: 'BidEntity',
  DEAL_ENTITY: 'DealEntity',
  NOMENCLATURE_ENTITY: 'NomenclatureEntity',
  CONTRACTORS_ENTITY: 'ContractorsEntity',
  EXPECTED_REPAYMENT_ENTITY: 'ExpectedRepaymentEntity',
  ACTUAL_REPAYMENT_ENTITY: 'ActualRepaymentEntity',
  EXPECTED_INCOME_ENTITY: 'ExpectedIncomeEntity',
  ACTUAL_INCOME_ENTITY: 'ActualIncomeEntity',
  WALLET_ENTITY: 'WalletEntity',
  PLATFORM_ENTITY: 'PlatformEntity',
  TRANSPORT_COMPANIES_ENTITY: 'TransportCompaniesEntity',
  LEGAL_ENTITY: 'LegalEntityEntity',
  DEAL_SHIPMENT_ENTITY: 'DealShipmentEntity',
  CASH_REQUEST_ENTITY: 'CashRequestEntity',
  CASH_DEPOSIT_ENTITY: 'CashDepositEntity',
  MANAGER_ENTITY: 'ManagerEntity',
};

export const SIMPLE_DATE_FORMAT = 'DD.MM.YYYY';

export const EXPECTED_INCOMENS_FILTER_INITIAL_VALUES = {
  id: null,
  deal_id: null,
  deal__buyer_id__in: [],
};

export const PAYMENT_FILTER_INITIAL_VALUES = {
  id: null,
  deal: null,
  deal__trading_manager__id__in: [],
  deal__seller_id__in: [],
  is_cash: null,
  source: null,
  status: null,
};

export const BID_FILTER_INITIAL_VALUES = {
  created__gte: null,
  created__lte: null,
  pickup_start_date__gte: null,
  pickup_start_date__lte: null,
  seller_id__in: [],
  manager_id__in: [],
  shipments__nomenclature_id__in: [],
  status__in: [],
};
export const NOMENCLATURE_FILTER_INITIAL_VALUES = {
  id: null,
  name__in: [],
  is_active: null,
};

export const REQUESTS_FILTER_INITIAL_VALUES = {
  created__gte: null,
  created__lte: null,
  wallet__owner_id__in: [],
  status__in: [],
};

export const TRANSPORT_COMPANIES_FILTER_INITIAL_VALUES = {
  id: null,
  name__icontains: null,
  is_active: null,
};

export const CONTRACTORS_FILTER_INITIAL_VALUES = {
  id: null,
  name__icontains: null,
  company_type__in: [],
  nomenclature_type__contains: [],
  is_active: null,
};

export const CASH_DEPOSITS_FILTER_INITIAL_VALUES = {
  created__gte: null,
  created__lte: null,
  wallet__owner_id__in: [],
  status__in: [],
};

export const UBIRATOR_DELIVERY_RESPONSIBILITY = '9';
export const SHEET_LOADING_SIZE = 20;

export const CASH_DEPOSIT_STATUSES = {
  notPayed: 3,
  payed: 6,
  canceled: 9,
};

export const USER_ROLES = {
  manager: 3,
  accountant: 6,
  other: 9,
};

export const USER_POSITIONS = {
  common: 3,
  chief: 6,
};

export const CONTRACTOR_TYPES = {
  buyer: 3,
  seller: 6,
};

export const YANDEX_API_KEY = '21f87199-89ab-4017-8729-dff88555fa76';
export const YANDEX_SUGGEST_API_KEY = '19e415eb-b2f1-42d8-8f58-73c2b3f4a19c';
