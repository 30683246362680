import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'src/store/main/slice';

const useSidebarOpen = () => {
  const dispatch = useDispatch();

  const isSidebarOpen = useSelector((store: any) => store.main.isSidebarOpen);

  const toggleSidebarOpen = () => dispatch(actions.toggleSidebarOpen());

  return { isSidebarOpen, toggleSidebarOpen };
};

export default useSidebarOpen;
