import { createSlice } from '@reduxjs/toolkit';
import { merge, reduce } from 'lodash';

import { ENTITY_TYPES } from 'src/constants';

const initialState = reduce(
  ENTITY_TYPES,
  (result) => {
    result = merge(
      {
        dealShipment: null,
        loading: false,
      },
    );
    return result;
  },
  {},
);

const slice = createSlice({
  name: 'dealShipment',
  initialState,
  reducers: {
    createDealShipmentStart(state, { payload }) {},
    createDealShipmentSuccess(state, { payload }) {},
    createDealShipmentFailure(state, { payload }) {},
    editDealShipmentStart(state, { payload }) {},
    editDealShipmentSuccess(state, { payload }) {},
    editDealShipmentFailure(state, { payload }) {},
    deleteDealShipmentStart(state, { payload }) {},
    deleteDealShipmentSuccess(state, { payload }) {},
    deleteDealShipmentFailure(state, { payload }) {},
  },
});

export const { actions } = slice;

export default slice.reducer;
