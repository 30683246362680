import {
  parseBidsFilterParams,
  parseDealsFilterParams,
  parseNomenclaturesFilterParams,
  parseTransportCompaniesFilterParams,
  parseContractorsFilterParams,
  parseRequestFilterParams,
  parseCashDepositFilterParams,
  parseExpectedRepaymentFilterParams,
  parseExpectedIncomesFilterParams,
} from './index';

const entities = {
  BidEntity: parseBidsFilterParams,
  DealEntity: parseDealsFilterParams,
  NomenclatureEntity: parseNomenclaturesFilterParams,
  TransportCompaniesEntity: parseTransportCompaniesFilterParams,
  ContractorsEntity: parseContractorsFilterParams,
  CashRequestEntity: parseRequestFilterParams,
  CashDepositEntity: parseCashDepositFilterParams,
  ExpectedRepaymentEntity: parseExpectedRepaymentFilterParams,
  ExpectedIncomeEntity: parseExpectedIncomesFilterParams,
};
function parseFilterParams({ entityType, data }) {
  const parse = entities[entityType];
  if (entityType && parse) {
    return parse(data);
  }
  return data;
}

export default parseFilterParams;
