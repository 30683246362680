import get from 'lodash/get';
import {
  useState, useEffect, createContext, useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import { actions } from 'src/store/extra/slice';

const ChoiceFieldsContext = createContext({});

function useChoiceFields(path: string) {
  const context = useContext(ChoiceFieldsContext);
  const choiceFields = get(context, path, {});
  if (path) {
    return choiceFields;
  }
  return context;
}

const ChoiceFieldsProvider = ({ children, initialState }) => {
  const dispatch = useDispatch();
  const [choiceFields] = useState(initialState);
  useEffect(() => {
    dispatch(actions.setState({ choiceFields }));
  }, [choiceFields]);

  return (
    <ChoiceFieldsContext.Provider value={choiceFields}>{children}</ChoiceFieldsContext.Provider>
  );
};

export { ChoiceFieldsProvider, useChoiceFields };
