import {
  put, takeEvery, call, select,
} from 'redux-saga/effects';

import api from 'src/lib/api';
import getEntityProps from 'src/entities/props';
import { actions as snackbar } from 'src/store/snackbar/slice';
import { actions as entityActions } from 'src/store/entity/slice';

import getResponseErrorMessage from 'src/lib/utils';
import getSnackbarMessage from 'src/lib/snackbar';
import { ENTITY_TYPES } from 'src/constants';
import { diff } from 'src/lib/helper';
import { actions } from './slice';
import parseRequest from '../../lib/parser/request';

const entityType = ENTITY_TYPES.PLATFORM_ENTITY;
const parseRequestData = (data) => parseRequest({ entityType, data });

function* createPlatform({ payload }) {
  const data = parseRequestData(payload);
  try {
    const endpoint = getEntityProps([entityType, 'endpoint']);
    const response = yield call(api.post, endpoint, { data });
    yield put(snackbar.openSuccessSnackbar(`Площадка контрагента ${response.id} успешно создана`));
    yield put(entityActions.getListStart(
      { entityType, values: { contractor_id: data.contractor } },
    ));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

function* getPlatform({ payload }) {
  const { id } = payload;
  try {
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}${id}/`;
    const response = yield call(api.get, endpoint, {});
    yield put(actions.getPlatformSuccess({ response }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

function* editPlatform({ payload }) {
  const { id } = payload;
  const data = parseRequestData(payload);
  try {
    const initialValues = yield select((store) =>
      store.platform.platform);
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}${id}/`;
    const response = yield call(api.patch, endpoint, {
      data: parseRequestData(diff(initialValues, payload)),
    });
    yield put(actions.editPlatformSuccess({ response }));
    yield put(snackbar.openSuccessSnackbar(`Площадка контрагента ${response.id} успешно обновлена`));
    yield put(entityActions.getListStart(
      { entityType, values: { contractor_id: data.contractor.id } },
    ));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

export default function* sagas() {
  yield takeEvery(actions.createPlatformStart, createPlatform);
  yield takeEvery(actions.getPlatformStart, getPlatform);
  yield takeEvery(actions.editPlatformStart, editPlatform);
}
