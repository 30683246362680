import {
  put, takeEvery, call,
} from 'redux-saga/effects';

import api from 'src/lib/api';
import getEntityProps from 'src/entities/props';
import { actions as snackbar } from 'src/store/snackbar/slice';
import { actions as entityActions } from 'src/store/entity/slice';

import getResponseErrorMessage from 'src/lib/utils';
import getSnackbarMessage from 'src/lib/snackbar';
import { ENTITY_TYPES } from 'src/constants';
import { actions } from './slice';
import parseRequest from '../../lib/parser/request';

const entityType = ENTITY_TYPES.CASH_DEPOSIT_ENTITY;
const entityTypeRequest = ENTITY_TYPES.CASH_REQUEST_ENTITY;
const parseRequestData = (data) => parseRequest({ entityType, data });

function* createCashDeposit({ payload }) {
  const { cash_request: cashRequest } = payload;
  try {
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}`;
    const response = yield call(api.post, endpoint, { data: parseRequestData(payload) });
    yield put(snackbar.openSuccessSnackbar(`Поступление наличных ${response.id} успешно создан`));
    const endpointRequest = `${getEntityProps([entityTypeRequest, 'endpoint'])}`;
    const responseRequest = yield call(api.get, endpointRequest, {});
    yield put(entityActions.getListSuccess({
      response: responseRequest,
      entityType: entityTypeRequest,
    }));
    yield put(entityActions.getListStart({
      entityType,
      values: {
        cash_request: cashRequest,
      },
    }));
    // TODO: Find out why it doesn't work
    // yield put(entityActions.getListStart({ entityType, value: {} }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

function* getCashDeposit({ payload }) {
  const { id } = payload;
  try {
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}${id}/`;
    const response = yield call(api.get, endpoint, {});
    yield put(actions.getCashDepositSuccess({ response }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

function* editCashDeposit({ payload }) {
  const { id } = payload;
  try {
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}${id}/`;
    const response = yield call(api.patch, endpoint, { data: parseRequestData(payload) });
    yield put(actions.editCashDepositSuccess({ response }));
    yield put(snackbar.openSuccessSnackbar(`Поступление наличных ${response.id} успешно обновлено`));
    yield put(entityActions.getListStart({ entityType, values: {} }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

export default function* sagas() {
  yield takeEvery(actions.createCashDepositStart, createCashDeposit);
  yield takeEvery(actions.getCashDepositStart, getCashDeposit);
  yield takeEvery(actions.editCashDepositStart, editCashDeposit);
}
