import getEntityProps from 'src/entities/props';

interface snackbarMessage {
  entityType?: string
  messageType: string
}
const snackbarMessages = {
  createSuccess: 'Успешно добавлено',
  createError: 'Ошибка при добавлении.',
  getListError: 'Не удалось получить/обновить список.',
};

const getSnackbarMessage = ({ entityType, messageType }: snackbarMessage) =>
  getEntityProps([entityType, 'snackbarMessages', messageType], snackbarMessages[messageType]);

export default getSnackbarMessage;
