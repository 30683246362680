const merge = require('lodash/merge');

const target = process.env.NEXT_PUBLIC_NODE_TARGET;

const configs = {
  all: {
    env: process.env.NEXT_PUBLIC_NODE_TARGET || 'development',
    host: process.env.HOST || 'localhost',
    port: process.env.PORT || 4200,
    isProd: false,
    isBrowser: typeof window !== 'undefined',
    sentryEndpoint: 'https://e5f7286373674fab811b61bae2519e35@sentry.io/1433913',
    captchaKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    apiUrl: 'http://localhost:4200/api/',
    isDev: true,
  },
  staging: {
    isProd: false,
    apiUrl: 'https://utrade.awkward.me/api/',
    sentryEndpoint: 'https://454cedfb6ab049c7aafc6bc27045fe5d@sentry.io/1440822',
    captchaKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  },
  production: {
    isProd: true,
    apiUrl: 'https://utrade.ubirator.com/api/',
    sentryEndpoint: 'https://454cedfb6ab049c7aafc6bc27045fe5d@sentry.io/1440822',
    captchaKey: '6Lf9a48UAAAAAHo22ejNXWVhbh19jJA9zCiReH5n',
  },
};

module.exports = merge(configs.all, configs[target]);
