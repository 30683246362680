import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  choiceFields: {},
  filterOpen: true,
  changeHistoryObjecId: null,
  userInfo: [],
  setting: null,
};

const extraSlice = createSlice({
  name: 'extra',
  initialState,
  reducers: {
    setState(state, { payload }) {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
  },
});

export const { actions } = extraSlice;

export default extraSlice.reducer;
