import {
  put, takeEvery, call, select,
} from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import api from 'src/lib/api';
import getEntityProps from 'src/entities/props';
import { actions as snackbar } from 'src/store/snackbar/slice';
import { actions as entityActions } from 'src/store/entity/slice';
import { actions as dealShipmentsActions } from 'src/store/dealShipment/slice';
import { actions } from 'src/store/deal/slice';
import getResponseErrorMessage from 'src/lib/utils';
import getSnackbarMessage from 'src/lib/snackbar';
import parseRequest from 'src/lib/parser/request';
import { diff } from 'src/lib/helper';

const entityType = 'DealEntity';

const parseRequestData = (data) => parseRequest({ entityType, data });

function* createDeal({ payload }) {
  try {
    const endpoint = getEntityProps([entityType, 'endpoint']);
    const response = yield call(api.post, endpoint, { data: parseRequestData(payload) });
    yield put(snackbar.openSuccessSnackbar(`Сделка ${response.id} успешно создана`));
    yield put(entityActions.getListStart({ entityType, values: {} }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

function* getDeal({ payload }) {
  const { id } = payload;
  try {
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}${id}/`;
    const response = yield call(api.get, endpoint, {});
    yield put(actions.getDealSuccess({ response }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

function* editDeal({ payload }) {
  const { id, shipments, isAccountant } = payload;

  const newShipments = shipments && shipments.reduce((acc, shipment) => {
    if (!shipment.id) {
      acc.push(shipment);
    }
    return acc;
  }, []);

  const isShipmentsEmpty = shipments && shipments.some((shipment) => isEmpty(shipment));

  const existedShipments = yield select((store) => store.entity.DealShipmentEntity.list);
  try {
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}${id}/`;

    if (!isAccountant) {
      if (!isShipmentsEmpty && shipments !== undefined) {
        for (const shipment of newShipments) {
          yield put(dealShipmentsActions.createDealShipmentStart({ deal: id, ...shipment }));
        }

        for (const existedShipment of existedShipments) {
          if (shipments.find((shipment) => existedShipment.id === shipment.id)) {
            const editableShipment = shipments.find((shipment) => existedShipment.id === shipment.id);
            yield put(dealShipmentsActions.editDealShipmentStart({ ...editableShipment }));
          } else {
            yield put(dealShipmentsActions.deleteDealShipmentStart({ ...existedShipment }));
          }
        }
      }
    }

    const initialValues = yield select((store) =>
      store.deal.deal);
    const response = yield call(api.patch, endpoint, { data: parseRequestData(diff(initialValues, payload)) });

    yield put(actions.editDealSuccess({ response }));
    yield put(snackbar.openSuccessSnackbar(`Сделка ${response.id} успешно обновлена`));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

export default function* sagas() {
  yield takeEvery(actions.createDealStart, createDeal);
  yield takeEvery(actions.getDealStart, getDeal);
  yield takeEvery(actions.editDealStart, editDeal);
}
