const entities = {};

function parseListResponse({ results, entityType }) {
  const parse = entities[entityType];
  if (entityType && parse) {
    return parse(results);
  }
  return results;
}

export default parseListResponse;
