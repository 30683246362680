import flow from 'lodash/flow';

import {
  formatDateByKey,
  setEndDate,
  setIdByKey,
  setShipments,
  setStatusByKey,
  setOtherByKey,
  removeByKey,
  getAddressByKey,
  formatTimeByKey,
  getNumberPhoneByKey,
  getReturnID,
  getNumberTypeByKey,
  removeIfNotBase64,
  setNumber, setPaymentStatus, setRepaymentType, setIsCash,
} from './utils';

const entities = {
  BidEntity(data) {
    const parse = flow([
      formatDateByKey('pickup_start_date'),
      setEndDate('pickup_end_date'),
      setIdByKey('seller'),
      setIdByKey('manager'),
      setIdByKey('cancel_reason'),
      setShipments('shipments'),
    ]);
    return parse(data);
  },
  NomenclatureEntity(data) {
    const parse = flow([
      setOtherByKey('other'),
      removeByKey('other'),
      setStatusByKey('status'),
    ]);
    return parse(data);
  },
  DealEntity(data) {
    const parse = flow([
      setIdByKey('bid'),
      setIdByKey('buyer'),
      setIdByKey('buyer_entity'),
      setIdByKey('buyer_platform'),
      setIdByKey('seller'),
      setIdByKey('seller_entity'),
      setIdByKey('seller_platform'),
      setIdByKey('sales_manager'),
      setIdByKey('trading_manager'),
      setIdByKey('transport_company'),
      setIdByKey('cancel_reason'),
      setIdByKey('type'),
      setIdByKey('our_entity_buy'),
      setIdByKey('our_entity_sell'),
      formatDateByKey('fact_machine_date'),
      formatDateByKey('plane_machine_date'),
      formatTimeByKey('plane_machine_start_time'),
      formatTimeByKey('plane_machine_end_time'),
      formatTimeByKey('loading_start_time'),
      formatTimeByKey('loading_finish_time'),
      formatTimeByKey('fact_machine_time'),
      removeIfNotBase64('buyer_document'),
      removeIfNotBase64('seller_document'),
    ]);
    return parse(data);
  },
  TransportCompaniesEntity(data) {
    const parse = flow([
      getNumberPhoneByKey('phone'),
      getAddressByKey('legal_address'),
      getAddressByKey('actual_address'),
      setIdByKey('taxation_system'),
    ]);
    return parse(data);
  },
  ContractorsEntity(data) {
    const parse = flow([
      getReturnID('company_type'),
      getNumberTypeByKey('type'),
      getNumberTypeByKey('nomenclature_type'),
      setStatusByKey('is_active'),
    ]);
    return parse(data);
  },
  PlatformEntity(data) {
    const parse = flow([
      getAddressByKey('address'),
      getNumberTypeByKey('loading_type'),
    ]);
    return parse(data);
  },
  LegalEntityEntity(data) {
    const parse = flow([
      getAddressByKey('legal_address'),
      getAddressByKey('actual_address'),
      getNumberPhoneByKey('phone'),
    ]);
    return parse(data);
  },

  DealShipmentEntity(data) {
    const parse = flow([
      setIdByKey('nomenclature'),
      removeByKey('created'),
      removeByKey('modified'),
    ]);
    return parse(data);
  },

  ExpectedIncomeEntity(data) {
    const parse = flow([
      setNumber('source'),
      setNumber('deal'),
      setIsCash('is_cash'),
      formatDateByKey('pay_date'),
      setRepaymentType('income'),
    ]);
    return parse(data);
  },

  ExpectedRepaymentEntity(data) {
    const parse = flow([
      setNumber('source'),
      setNumber('expense_type'),
      setNumber('deal'),
      setIsCash('is_cash'),
      formatDateByKey('date'),
      setRepaymentType('expense'),
      setPaymentStatus('status'),
      setIdByKey('wallet'),
    ]);
    return parse(data);
  },

  PaymentEntity(data) {
    const parse = flow([
      setNumber('source'),
      setNumber('type'),
      setNumber('deal'),
      formatDateByKey('date'),
      setPaymentStatus('status'),
    ]);
    return parse(data);
  },
  CashRequestEntity(data) {
    const parse = flow([
      setIdByKey('deal'),
      setIdByKey('payment'),
    ]);
    return parse(data);
  },
  CashDepositEntity(data) {
    const parse = flow([
      setIdByKey('transfer_method'),
      setIdByKey('accountant'),
      setNumber('transfer_method'),
    ]);
    return parse(data);
  },
  ActualRepaymentEntity(data) {
    const parse = flow([
      formatDateByKey('pay_date'),
    ]);
    return parse(data);
  },
};

function parseRequest({ entityType, data, initialValues = {} }) {
  const parse = entities[entityType];
  if (entityType && parse) {
    return parse(data, initialValues);
  }
  return data;
}

export default parseRequest;
