import { createSlice } from '@reduxjs/toolkit';
import { merge, reduce } from 'lodash';

import { ENTITY_TYPES } from 'src/constants';

const initialState = reduce(
  ENTITY_TYPES,
  (result) => {
    result = merge(
      {
        cashRequest: null,
        loading: false,
      },
    );
    return result;
  },
  {},
);

const slice = createSlice({
  name: 'cashRequest',
  initialState,
  reducers: {
    createCashRequestStart(state, { payload }) { },
    createCashRequestSuccess(state, { payload }) { },
    createCashRequestFailure(state, { payload }) { },
    getCashRequestStart(state, { payload }) {
      state.loading = true;
    },
    getCashRequestSuccess(state, { payload }) {
      const { response } = payload;
      state.cashRequest = response;
      state.loading = false;
    },
    getCashRequestFailure(state, { payload }) { },
    editCashRequestStart(state, { payload }) {
      state.loading = true;
    },
    editStatusPaidCashRequestStart(state, { payload }) {
      state.loading = true;
    },
    editStatusRequestedCashRequestStart(state, { payload }) {
      state.loading = true;
    },
    editCashRequestAccountantCommentStart(state, { payload }) {
      state.loading = true;
    },
    editCashRequestSuccess(state, { payload }) {
      const { response } = payload;
      state.loading = false;
      state.cashRequest = response;
    },
    editCashRequestFailure(state, { payload }) { },
  },
});

export const { actions } = slice;

export default slice.reducer;
