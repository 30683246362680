import 'moment/locale/ru';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import NextApp from 'next/app';
import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import Cookies from 'cookies';
import { YMInitializer } from 'react-yandex-metrika';
import { YMaps } from '@pbe/react-yandex-maps';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMediaQuery } from '@mui/material';

import { ChoiceFieldsProvider } from 'src/contexts/choiceFields';
import { AuthProvider } from 'src/contexts/auth';
import Main from 'src/components/Main';
import Snackbars from 'src/components/Snackbars';

import configureStore from 'src/store';
import { YANDEX_API_KEY, YANDEX_SUGGEST_API_KEY } from 'src/constants';
import config from '../config';
import '../styles/globals.css';

const store = configureStore({});

const App = ({
  Component, pageProps, choiceFields, authData = {},
}: AppProps | any) => {
  const isMobile = useMediaQuery('(max-width:768px)');
  return (
    <Provider store={store}>
      <AuthProvider initialState={authData}>
        <YMaps query={{ apikey: YANDEX_API_KEY, suggest_apikey: YANDEX_SUGGEST_API_KEY }}>
          <ChoiceFieldsProvider initialState={choiceFields}>
            <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
              <NotistackSnackbarProvider maxSnack={3}>
                <Main isMobile={isMobile}>
                  <Snackbars />
                  {config.isProd && (
                    <YMInitializer
                      accounts={[89461007]}
                      options={{
                        clickmap: true,
                        trackLinks: true,
                        accurateTrackBounce: true,
                        webvisor: true,
                      }}
                      version="2"
                    />
                  )}
                  <Component {...pageProps} isMobile={isMobile} />
                </Main>
              </NotistackSnackbarProvider>
            </LocalizationProvider>
          </ChoiceFieldsProvider>
        </YMaps>
      </AuthProvider>
    </Provider>
  );
};

// eslint-disable-next-line consistent-return
const getFetchData = ({ csrftoken, sessionid }) => {
  if (csrftoken && sessionid) {
    return {
      headers: {
        Accept: 'application/json',
        Cookie: `sessionid=${sessionid}; csrftoken=${csrftoken};`,
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      method: 'GET',
    };
  }
};

const ssrFetch: (cookie) => Promise<any> = async (cookie) =>
  fetch(`${config.apiUrl}/api/users/get_state/`, getFetchData(cookie))
    .then(async (response) => {
      const authData = await response.json();
      return {
        headers: response.headers,
        authData,
      };
    })
    .catch((e) => {
      console.error(e);
      return {};
    });

const getChoiceFieldsRequest = async () =>
  fetch(`${config.apiUrl}extra/choice_fields/`)
    .then(async (response) => {
      const choiceFields = await response.json();
      return choiceFields;
    })
    .catch(noop);

App.getInitialProps = async (context) => {
  const appProps = await NextApp.getInitialProps(context);
  if (context.ctx.req && !isEmpty(context.ctx.req.headers)) {
    const cookies = new Cookies(context.ctx.req, context.ctx.res);
    const csrftoken = cookies.get('csrftoken');
    const sessionid = cookies.get('sessionid');
    const { headers, authData } = await ssrFetch({ csrftoken, sessionid });
    const choiceFields = await getChoiceFieldsRequest();
    if (!csrftoken) {
      cookies.set('csrftoken', headers.get('set-cookie').replace('csrftoken=', ''), {
        httpOnly: false,
      });
    }
    return { ...appProps, authData, choiceFields };
  }
  return { ...appProps };
};

export default App;
