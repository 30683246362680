import { createSlice } from '@reduxjs/toolkit';
import { merge, reduce } from 'lodash';

import { ENTITY_TYPES } from 'src/constants';

const initialState = reduce(
  ENTITY_TYPES,
  (result) => {
    result = merge(
      {
        bid: null,
        loading: false,
      },
    );
    return result;
  },
  {},
);

const slice = createSlice({
  name: 'bid',
  initialState,
  reducers: {
    createBidStart(state, { payload }) {},
    createBidSuccess(state, { payload }) {
    },
    createBidFailure(state, { payload }) {},
    getBidStart(state, { payload }) {
      state.loading = true;
    },
    getBidSuccess(state, { payload }) {
      const { response } = payload;
      state.bid = response;
      state.loading = false;
    },
    getBidFailure(state, { payload }) {},
    editBidStart(state, { payload }) {
      state.loading = true;
    },
    editBidSuccess(state, { payload }) {
      const { response } = payload;
      state.loading = false;
      state.bid = response;
    },
    editBidFailure(state, { payload }) {},

  },
});

export const { actions } = slice;

export default slice.reducer;
