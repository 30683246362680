import { createSlice } from '@reduxjs/toolkit';
import { merge, reduce } from 'lodash';

import { ENTITY_TYPES } from 'src/constants';

const initialState = reduce(
  ENTITY_TYPES,
  (result) => {
    result = merge(
      {
        repayment: null,
        loading: false,
      },
    );
    return result;
  },
  {},
);

const slice = createSlice({
  name: 'actualRepayment',
  initialState,
  reducers: {
    createActualRepaymentStart(state, { payload }) {},
    createActualRepaymentSuccess(state, { payload }) {},
    createActualRepaymentFailure(state, { payload }) {},
    getActualRepaymentStart(state, { payload }) {
      state.loading = true;
    },
    getActualRepaymentSuccess(state, { payload }) {
      const { response } = payload;
      state.actualRepayment = response;
      state.loading = false;
    },
    getActualRepaymentFailure(state, { payload }) {},
    editActualRepaymentStart(state, { payload }) {
      state.loading = true;
    },
    editActualRepaymentSuccess(state, { payload }) {
      const { response } = payload;
      state.loading = false;
      state.actualRepayment = response;
    },
    editActualRepaymentFailure(state, { payload }) {},

    createActualIncomeStart(state, { payload }) {},
    createActualIncomeSuccess(state, { payload }) {},
    createActualIncomeFailure(state, { payload }) {},

  },
});

export const { actions } = slice;

export default slice.reducer;
