import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSidebarOpen: true,
  isFilterOpen: false,
  modalWindowsState: {
    isCreateOpen: false,
    isDetailsOpen: false,
    isEditOpen: false,
    isCancelReasonOpen: false,
    collapsedDataId: false,
  },
};

const extraSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    toggleSidebarOpen(state) {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    toggleFilterOpen(state) {
      state.isFilterOpen = !state.isFilterOpen;
    },
    setModalWindowsState(state, { payload }) {
      state.modalWindowsState = payload;
    },
    setState(state, { payload }) {
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
  },
});

export const { actions } = extraSlice;

export default extraSlice.reducer;
