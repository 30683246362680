import {
  put, takeEvery, call,
} from 'redux-saga/effects';

import api from 'src/lib/api';
import getEntityProps from 'src/entities/props';
import { actions as snackbar } from 'src/store/snackbar/slice';
import { actions as entityActions } from 'src/store/entity/slice';
import getResponseErrorMessage from 'src/lib/utils';
import getSnackbarMessage from 'src/lib/snackbar';
import { ENTITY_TYPES, REPAYMENT_TYPES, SHEET_LOADING_SIZE } from 'src/constants';
import parseRequest from 'src/lib/parser/request';
import { actions } from './slice';

const entityType = ENTITY_TYPES.ACTUAL_REPAYMENT_ENTITY;
const parseRequestData = (data) => parseRequest({ entityType, data });

function* createActualRepayment({ payload }) {
  try {
    const endpoint = getEntityProps([entityType, 'endpoint']);
    const response = yield call(api.post, endpoint, { data: parseRequestData(payload) });
    yield put(snackbar.openSuccessSnackbar(`Фактическая выплата ${response.id} успешно создана`));
    yield put(entityActions.getListStart({ entityType: 'ExpectedRepaymentEntity', values: { deal_id: payload.dealId, type: REPAYMENT_TYPES.expense } }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

function* createActualIncome({ payload }) {
  const { dealId } = payload;
  try {
    const endpoint = getEntityProps([entityType, 'endpoint']);
    const response = yield call(api.post, endpoint, { data: parseRequestData(payload) });
    yield put(snackbar.openSuccessSnackbar(`Фактическое поступление ${response.id} успешно создано`));
    yield put(entityActions.getListStart({ entityType: 'ExpectedRepaymentEntity', values: { page_size: SHEET_LOADING_SIZE, type: REPAYMENT_TYPES.income, deal_id: dealId } }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

function* getActualRepayment({ payload }) {
  const { id } = payload;
  try {
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}${id}/`;
    const response = yield call(api.get, endpoint, {});
    yield put(actions.getActualRepaymentSuccess({ response }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

function* editActualRepayment({ payload }) {
  const { id } = payload;
  try {
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}${id}/`;
    const response = yield call(api.patch, endpoint, { data: parseRequestData(payload) });
    yield put(actions.editActualRepaymentSuccess({ response }));
    yield put(snackbar.openSuccessSnackbar(`Ожидаемое поступление ${response.id} успешно обновлена`));
    yield put(entityActions.getListStart({ entityType, values: {} }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

export default function* sagas() {
  yield takeEvery(actions.createActualRepaymentStart, createActualRepayment);
  yield takeEvery(actions.createActualIncomeStart, createActualIncome);
  yield takeEvery(actions.getActualRepaymentStart, getActualRepayment);
  yield takeEvery(actions.editActualRepaymentStart, editActualRepayment);
}
