import React, { useState } from 'react';
import Link from 'next/link';
import {
  ListItemButton, ListItemIcon, ListItemText, Box, BottomNavigation,
} from '@mui/material';
import { useRouter } from 'next/router';
import { useAuth } from 'src/contexts/auth';
import { USER_POSITIONS, USER_ROLES } from 'src/constants';

import Download from 'public/svg/download.svg';
import RequestIcon from 'public/svg/request.svg';
import Upload from 'public/svg/upload.svg';
import Card from 'public/svg/credit-card.svg';
import More from 'public/svg/other-more-horizontal.svg';
import Book from 'public/svg/book.svg';
import ChevronDown from 'public/svg/chevron-down.svg';
import ChevronUp from 'public/svg/chevron-up.svg';
import Deal from 'public/svg/deal.svg';
import ManagerIcon from 'public/svg/managerIcon.svg';
import CollapseDirectory from 'src/components/Collapse';
import ModalWindow from 'src/components/Popup';
import CloseIcon from 'public/svg/close.svg';
import Logo from 'public/svg/logo.svg';
import { BurgerMenuHeader, ButtonMobile, CustomListTabBar } from 'src/components/Layout';

const Footer = ({ open }) => {
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const [isOpenBurgerMenu, setIsOpenBurgerMenu] = useState(false);
  const route = useRouter();
  const { authData: { user: { role, position } } } = useAuth();
  const isManager = role === USER_ROLES.manager;
  const isAccountant = role === USER_ROLES.accountant;
  const isChief = position === USER_POSITIONS.chief;

  return (
    <BottomNavigation>
      <ModalWindow
        isMobile
        translate="translate(-50%, -43%)"
        open={isOpenBurgerMenu}
        isOverflow
        handleClose={() => setIsOpenBurgerMenu(!isOpenBurgerMenu)}
      >
        <>
          <BurgerMenuHeader>
            <Box sx={{ minWidth: '90px' }}><Logo /></Box>
            <CloseIcon onClick={() => setIsOpenBurgerMenu(false)} />
          </BurgerMenuHeader>
          <Box sx={{ width: '100%', backgroundColor: 'white', height: '74vh' }}>
            {isManager && (
              <>
                <Link href="/">
                  <ListItemButton onClick={() => setIsOpenBurgerMenu(false)} sx={route.pathname === '/' && { backgroundColor: '#EEF7FF' }}>
                    <ListItemIcon>
                      <Download fill="#676767" />
                    </ListItemIcon>
                    <ListItemText primary="Заявки" />
                  </ListItemButton>
                </Link>
                <Link href="/deals">
                  <ListItemButton onClick={() => setIsOpenBurgerMenu(false)} sx={route.pathname === '/deals' && { backgroundColor: '#EEF7FF' }}>
                    <ListItemIcon>
                      <Deal fill="#676767" />
                    </ListItemIcon>
                    <ListItemText primary="Сделки" />
                  </ListItemButton>
                </Link>
                <Link href="/wallet">
                  <ListItemButton onClick={() => setIsOpenBurgerMenu(false)} sx={route.pathname === '/wallet' && { backgroundColor: '#EEF7FF' }}>
                    <ListItemIcon>
                      <Card />
                    </ListItemIcon>
                    <ListItemText primary="Кошелёк" />
                  </ListItemButton>
                </Link>
                <ListItemButton onClick={() => setIsOpenCollapse(!isOpenCollapse)}>
                  <ListItemIcon>
                    <Book />
                  </ListItemIcon>
                  <ListItemText primary="Справочники" />
                  {isOpenCollapse ? <ChevronUp /> : <ChevronDown />}
                </ListItemButton>
                {open && (
                  <CollapseDirectory
                    setIsOpenBurgerMenu={() => setIsOpenBurgerMenu(false)}
                    setIsOpenCollapse={setIsOpenCollapse}
                    isOpenCollapse={isOpenCollapse}
                    isMobile
                  />
                )}
              </>
            )}
            {isAccountant && isChief && (
              <>
                <Link href="/cash_deposits">
                  <ListItemButton sx={route.pathname === '/cash_deposits' && { backgroundColor: '#EEF7FF' }}>
                    <ListItemIcon>
                      <Upload />
                    </ListItemIcon>
                    <ListItemText primary="Поступление наличных" />
                  </ListItemButton>
                </Link>
                <Link href="/repayments">
                  <ListItemButton sx={route.pathname === '/payments' && { backgroundColor: '#EEF7FF' }}>
                    <ListItemIcon>
                      <Upload />
                    </ListItemIcon>
                    <ListItemText primary="Выплаты" />
                  </ListItemButton>
                </Link>
                <Link href="/expected_incomes">
                  <ListItemButton sx={route.pathname === '/expected_incomes' && { backgroundColor: '#EEF7FF' }}>
                    <ListItemIcon>
                      <Download />
                    </ListItemIcon>
                    <ListItemText primary="Ожидаемые поступления" />
                  </ListItemButton>
                </Link>
                <Link href="/requests">
                  <ListItemButton sx={route.pathname === '/requests' && { backgroundColor: '#EEF7FF' }}>
                    <ListItemIcon>
                      <RequestIcon />
                    </ListItemIcon>
                    <ListItemText primary="Запросы" />
                  </ListItemButton>
                </Link>
                <Link href="/managers">
                  <ListItemButton sx={route.pathname === '/managers' && { backgroundColor: '#EEF7FF' }}>
                    <ListItemIcon>
                      <ManagerIcon />
                    </ListItemIcon>
                    <ListItemText primary="Менеджеры" />
                  </ListItemButton>
                </Link>
                <Link href="/deals">
                  <ListItemButton sx={route.pathname === '/deals' && { backgroundColor: '#EEF7FF' }}>
                    <ListItemIcon>
                      <Deal />
                    </ListItemIcon>
                    <ListItemText primary="Сделки" />
                  </ListItemButton>
                </Link>
              </>
            )}
            {isAccountant && !isChief && (
              <>
                <Link href="/cash_deposits">
                  <ListItemButton sx={route.pathname === '/cash_deposits' && { backgroundColor: '#EEF7FF' }}>
                    <ListItemIcon>
                      <Upload />
                    </ListItemIcon>
                    <ListItemText primary="Поступление наличных" />
                  </ListItemButton>
                </Link>
                <Link href="/repayments">
                  <ListItemButton sx={route.pathname === '/payments' && { backgroundColor: '#EEF7FF' }}>
                    <ListItemIcon>
                      <Upload />
                    </ListItemIcon>
                    <ListItemText primary="Выплаты" />
                  </ListItemButton>
                </Link>
                <Link href="/expected_incomes">
                  <ListItemButton sx={route.pathname === '/expected_incomes' && { backgroundColor: '#EEF7FF' }}>
                    <ListItemIcon>
                      <Download />
                    </ListItemIcon>
                    <ListItemText primary="Ожидаемые поступления" />
                  </ListItemButton>
                </Link>
              </>
            )}
          </Box>
        </>
      </ModalWindow>
      <CustomListTabBar>
        <Box sx={{ display: 'flex' }}>
          {isManager && (
            <>
              <Link href="/">
                <ListItemButton sx={{ color: route.pathname === '/' ? '#0E79EC' : '#676767' }}>
                  <ButtonMobile>
                    <ListItemIcon sx={{ justifyContent: 'center', color: 'red' }}>
                      <Download fill={route.pathname === '/' ? '#0E79EC' : '#676767'} />
                    </ListItemIcon>
                    <ListItemText primary="Заявки" />
                  </ButtonMobile>
                </ListItemButton>
              </Link>
              <Link href="/deals">
                <ListItemButton sx={{ color: route.pathname === '/deals' || route.pathname === '/deals/[id]' ? '#0E79EC' : '#676767' }}>
                  <ButtonMobile>
                    <ListItemIcon sx={{ justifyContent: 'center' }}>
                      <Deal fill={route.pathname === '/deals' || route.pathname === '/deals/[id]' ? '#0E79EC' : '#676767'} />
                    </ListItemIcon>
                    <ListItemText primary="Сделки" />
                  </ButtonMobile>
                </ListItemButton>
              </Link>
              <Link href="/wallet">
                <ListItemButton sx={{ color: route.pathname === '/wallet' ? '#0E79EC' : '#676767' }}>
                  <ButtonMobile>
                    <ListItemIcon sx={{ justifyContent: 'center' }}>
                      <Card fill={route.pathname === '/wallet' ? '#0E79EC' : '#676767'} />
                    </ListItemIcon>
                    <ListItemText primary="Кошелёк" />
                  </ButtonMobile>
                </ListItemButton>
              </Link>
              <ListItemButton onClick={() => setIsOpenBurgerMenu(!isOpenBurgerMenu)}>
                <ButtonMobile>
                  <ListItemIcon sx={{ justifyContent: 'center' }}>
                    <More />
                  </ListItemIcon>
                  <ListItemText primary="Другое" />
                </ButtonMobile>
              </ListItemButton>
            </>
          )}
          {isAccountant && isChief && (
            <>
              <Link href="/cash_deposits">
                <ListItemButton sx={route.pathname === '/cash_deposits' && { backgroundColor: '#EEF7FF' }}>
                  <ButtonMobile>
                    <ListItemIcon sx={{ justifyContent: 'center' }}>
                      <Upload />
                    </ListItemIcon>
                    <ListItemText primary="Поступление наличных" />
                  </ButtonMobile>
                </ListItemButton>
              </Link>
              <Link href="/repayments">
                <ListItemButton sx={route.pathname === '/payments' && { backgroundColor: '#EEF7FF' }}>
                  <ButtonMobile>
                    <ListItemIcon sx={{ justifyContent: 'center' }}>
                      <Upload />
                    </ListItemIcon>
                    <ListItemText primary="Выплаты" />
                  </ButtonMobile>
                </ListItemButton>
              </Link>
              <Link href="/expected_incomes">
                <ListItemButton sx={route.pathname === '/expected_incomes' && { backgroundColor: '#EEF7FF' }}>
                  <ButtonMobile>
                    <ListItemIcon sx={{ justifyContent: 'center' }}>
                      <Download />
                    </ListItemIcon>
                    <ListItemText primary="Ожидаемые поступления" />
                  </ButtonMobile>
                </ListItemButton>
              </Link>
              <ListItemButton onClick={() => setIsOpenBurgerMenu(!isOpenBurgerMenu)}>
                <ButtonMobile>
                  <ListItemIcon sx={{ justifyContent: 'center' }}>
                    <More />
                  </ListItemIcon>
                  <ListItemText primary="Другое" />
                </ButtonMobile>
              </ListItemButton>
            </>
          )}
          {isAccountant && !isChief && (
            <>
              <Link href="/cash_deposits">
                <ListItemButton sx={route.pathname === '/cash_deposits' && { backgroundColor: '#EEF7FF' }}>
                  <ButtonMobile>
                    <ListItemIcon sx={{ justifyContent: 'center' }}>
                      <Upload />
                    </ListItemIcon>
                    <ListItemText primary="Поступление наличных" />
                  </ButtonMobile>
                </ListItemButton>
              </Link>
              <Link href="/repayments">
                <ListItemButton sx={route.pathname === '/payments' && { backgroundColor: '#EEF7FF' }}>
                  <ButtonMobile>
                    <ListItemIcon sx={{ justifyContent: 'center' }}>
                      <Upload />
                    </ListItemIcon>
                    <ListItemText primary="Выплаты" />
                  </ButtonMobile>
                </ListItemButton>
              </Link>
              <Link href="/expected_incomes">
                <ListItemButton sx={route.pathname === '/expected_incomes' && { backgroundColor: '#EEF7FF' }}>
                  <ButtonMobile>
                    <ListItemIcon sx={{ justifyContent: 'center' }}>
                      <Download />
                    </ListItemIcon>
                    <ListItemText primary="Ожидаемые поступления" />
                  </ButtonMobile>
                </ListItemButton>
              </Link>
            </>
          )}
        </Box>
      </CustomListTabBar>
    </BottomNavigation>
  );
};

export default Footer;
