import { createSlice } from '@reduxjs/toolkit';
import { merge, reduce } from 'lodash';

import { ENTITY_TYPES } from 'src/constants';

const initialState = reduce(
  ENTITY_TYPES,
  (result) => {
    result = merge(
      {
        expectedIncome: null,
        loading: false,
      },
    );
    return result;
  },
  {},
);

const slice = createSlice({
  name: 'expectedIncome',
  initialState,
  reducers: {
    createExpectedIncomeStart(state, { payload }) { },
    createExpectedIncomeSuccess(state, { payload }) { },
    createExpectedIncomeFailure(state, { payload }) { },
    getExpectedIncomeStart(state, { payload }) {
      state.loading = true;
    },
    getExpectedIncomeSuccess(state, { payload }) {
      const { response } = payload;
      state.expectedIncome = response;
      state.loading = false;
    },
    getExpectedIncomeFailure(state, { payload }) { },
    editExpectedIncomeStart(state, { payload }) {
      state.loading = true;
    },
    editExpectedIncomeSuccess(state, { payload }) {
      const { response } = payload;
      state.loading = false;
      state.expectedIncome = response;
    },
    editExpectedIncomeFailure(state, { payload }) { },
  },
});

export const { actions } = slice;

export default slice.reducer;
