import { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  List, ListItemButton, ListItemText, Box, Collapse,
} from '@mui/material';

const CollapseComponent = ({
  isOpenCollapse, setIsOpenCollapse, isMobile, setIsOpenBurgerMenu,
}) => {
  const route = useRouter();
  const [sliderPosition, setSliderPosition] = useState('0px');

  useEffect(() => {
    if (route.pathname === '/contractors') {
      setIsOpenCollapse(true);
      setSliderPosition('0px');
    } else if (route.pathname === '/nomenclatures') {
      setIsOpenCollapse(true);
      setSliderPosition('47px');
    } else if (route.pathname === '/transport-companies') {
      setIsOpenCollapse(true);
      setSliderPosition('96px');
    }
  }, [route.pathname]);

  return (
    <Collapse in={isOpenCollapse} timeout="auto" unmountOnExit>
      <Box
        sx={{ position: 'relative' }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '2px',
            left: '29px',
            zIndex: '1',
            height: '127px',
            top: '9px',
            backgroundColor: '#A0A0A0',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              backgroundColor: '#1E95FF',
              borderRadius: '6px',
              width: '6px',
              left: '-2px',
              top: `${sliderPosition}`,
              height: '32px',
            }}
          />
        </Box>
        <List component="div" disablePadding sx={!isMobile && { width: '276px' }}>
          <Link href="/contractors">
            <ListItemButton onClick={isMobile && setIsOpenBurgerMenu} sx={(route.pathname === '/contractors' || route.pathname === '/contractors/[id]') && { backgroundColor: '#EEF7FF' }}>
              <ListItemText sx={{ pl: 7 }} primary="Контрагенты" />
            </ListItemButton>
          </Link>
          <Link href="/nomenclatures">
            <ListItemButton onClick={isMobile && setIsOpenBurgerMenu} sx={route.pathname === '/nomenclatures' && { backgroundColor: '#EEF7FF' }}>
              <ListItemText sx={{ pl: 7 }} primary="Номенклатуры" />
            </ListItemButton>
          </Link>
          <Link href="/transport-companies">
            <ListItemButton onClick={isMobile && setIsOpenBurgerMenu} sx={route.pathname === '/transport-companies' && { backgroundColor: '#EEF7FF' }}>
              <ListItemText sx={{ pl: 7 }} primary="ТК" />
            </ListItemButton>
          </Link>
        </List>
      </Box>
    </Collapse>
  );
};

export default CollapseComponent;
