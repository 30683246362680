import { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
  Button,
} from '@mui/material';

import Download from 'public/svg/download.svg';
import RequestIcon from 'public/svg/request.svg';
import Upload from 'public/svg/upload.svg';
import Card from 'public/svg/credit-card.svg';
import Arrow from 'public/svg/arrow.svg';
import ArrowRight from 'public/svg/arrow-right.svg';
import Book from 'public/svg/book.svg';
import ChevronDown from 'public/svg/chevron-down.svg';
import ChevronUp from 'public/svg/chevron-up.svg';
import Deal from 'public/svg/deal.svg';
import Logo from 'public/svg/logo.svg';
import MiniLogo from 'public/svg/logo-mini.svg';
import ManagerIcon from 'public/svg/managerIcon.svg';
import LogoutIcon from 'public/svg/log-out.svg';

import CollapseComponent from 'src/components/Collapse';
import { useAuth } from 'src/contexts/auth';
import api from 'src/lib/api';
import { USER_POSITIONS, USER_ROLES } from 'src/constants';
import {
  CustomListSideBar, DrawerHeader, Role, StyledDrawer,
} from 'src/components/Layout';

const SidebarComponent = ({
  open, setOpen,
}) => {
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const route = useRouter();
  const { authData: { user: { role, position, full_name: fullName } } } = useAuth();
  const isManager = role === USER_ROLES.manager;
  const isAccountant = role === USER_ROLES.accountant;
  const isChief = position === USER_POSITIONS.chief;
  const handbookRoutes = ['/contractors', '/nomenclatures', '/transport-companies'];

  const logout = () => api.get('users/logout/', {})
    .then(() => {
      route.push('/login');
    });

  useEffect(() => !open && setOpen(true), [isOpenCollapse]);

  useEffect(() => {
    setIsOpenCollapse(true);
  }, []);

  return (
    <section>
      <StyledDrawer variant="permanent" open={open}>
        <DrawerHeader open={open}>
          {open ? (
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyItems: 'space-between',
            }}
            >
              <Box sx={{ minWidth: '90px' }}><Logo /></Box>
              <Role>
                {role === USER_ROLES.manager ? 'менеджер' : 'бухгалтер'} {isChief && <span>(рук)</span>}
              </Role>
            </Box>
          ) : (
            <MiniLogo />
          )}
        </DrawerHeader>
        <Divider />
        <CustomListSideBar>
          <nav>
            {isManager && (
              <>
                <Link href="/">
                  <ListItemButton selected={route.pathname === '/'}>
                    <ListItemIcon>
                      <Download fill={route.pathname === '/' ? '#1E95FF' : 'rgba(0, 0, 0, 0.54)'} />
                    </ListItemIcon>
                    <ListItemText primary="Заявки" />
                  </ListItemButton>
                </Link>
                <Link href="/deals">
                  <ListItemButton selected={route.pathname === '/deals' || route.pathname === '/deals/[id]'}>
                    <ListItemIcon>
                      <Deal fill={(route.pathname === '/deals' || route.pathname === '/deals/[id]') ? '#1E95FF' : 'rgba(0, 0, 0, 0.54)'} />
                    </ListItemIcon>
                    <ListItemText primary="Сделки" />
                  </ListItemButton>
                </Link>
                <ListItemButton onClick={() => setIsOpenCollapse(!isOpenCollapse)}>
                  <ListItemIcon>
                    <Book fill={handbookRoutes.includes(route.pathname) ? '#1E95FF' : 'rgba(0, 0, 0, 0.54)'} />
                  </ListItemIcon>
                  <ListItemText primary="Справочники" />
                  {isOpenCollapse ? <ChevronUp /> : <ChevronDown />}
                </ListItemButton>
                {open && (
                  <CollapseComponent
                    setIsOpenCollapse={setIsOpenCollapse}
                    isOpenCollapse={isOpenCollapse}
                    setIsOpenBurgerMenu={() => null}
                    isMobile={false}
                  />
                )}
                <Link href="/wallet">
                  <ListItemButton selected={route.pathname === '/wallet'}>
                    <ListItemIcon>
                      <Card fill={route.pathname === '/wallet' ? '#1E95FF' : 'rgba(0, 0, 0, 0.54)'} />
                    </ListItemIcon>
                    <ListItemText primary="Кошелёк" />
                  </ListItemButton>
                </Link>
              </>
            )}
            {isAccountant && isChief && (
              <>
                <Link href="/cash_deposits">
                  <ListItemButton selected={route.pathname === '/cash_deposits'}>
                    <ListItemIcon>
                      <Upload fill={(route.pathname === '/cash_deposits') ? '#1E95FF' : 'rgba(0, 0, 0, 0.54)'} />
                    </ListItemIcon>
                    <ListItemText primary="Поступление наличных" />
                  </ListItemButton>
                </Link>
                <Link href="/repayments">
                  <ListItemButton selected={route.pathname === '/repayments'}>
                    <ListItemIcon>
                      <Upload fill={(route.pathname === '/repayments') ? '#1E95FF' : 'rgba(0, 0, 0, 0.54)'} />
                    </ListItemIcon>
                    <ListItemText primary="Выплаты" />
                  </ListItemButton>
                </Link>
                <Link href="/expected_incomes">
                  <ListItemButton selected={route.pathname === '/expected_incomes'}>
                    <ListItemIcon>
                      <Download fill={route.pathname === '/expected_incomes' ? '#1E95FF' : 'rgba(0, 0, 0, 0.54)'} />
                    </ListItemIcon>
                    <ListItemText primary="Ожидаемые поступления" />
                  </ListItemButton>
                </Link>
                <Link href="/requests">
                  <ListItemButton selected={route.pathname === '/requests' || route.pathname === '/requests/[id]'}>
                    <ListItemIcon>
                      <RequestIcon fill={(route.pathname === '/requests' || route.pathname === '/requests/[id]') ? '#1E95FF' : 'rgba(0, 0, 0, 0.54)'} />
                    </ListItemIcon>
                    <ListItemText primary="Запросы" />
                  </ListItemButton>
                </Link>
                <Link href="/managers">
                  <ListItemButton selected={route.pathname === '/managers'}>
                    <ListItemIcon>
                      <ManagerIcon fill={route.pathname === '/managers' ? '#1E95FF' : 'rgba(0, 0, 0, 0.54)'} />
                    </ListItemIcon>
                    <ListItemText primary="Менеджеры" />
                  </ListItemButton>
                </Link>
                <Link href="/deals">
                  <ListItemButton selected={route.pathname === '/deals' || route.pathname === '/deals/[id]'}>
                    <ListItemIcon>
                      <Deal fill={route.pathname === '/deals' ? '#1E95FF' : 'rgba(0, 0, 0, 0.54)'} />
                    </ListItemIcon>
                    <ListItemText primary="Сделки" />
                  </ListItemButton>
                </Link>
              </>
            )}
            {isAccountant && !isChief && (
              <>
                <Link href="/cash_deposits">
                  <ListItemButton selected={route.pathname === '/cash_deposits'}>
                    <ListItemIcon>
                      <Upload fill={route.pathname === '/cash_deposits' ? '#1E95FF' : 'rgba(0, 0, 0, 0.54)'} />
                    </ListItemIcon>
                    <ListItemText primary="Поступление наличных" />
                  </ListItemButton>
                </Link>
                <Link href="/repayments">
                  <ListItemButton selected={route.pathname === '/repayments'}>
                    <ListItemIcon>
                      <Upload fill={route.pathname === '/repayments' ? '#1E95FF' : 'rgba(0, 0, 0, 0.54)'} />
                    </ListItemIcon>
                    <ListItemText primary="Выплаты" />
                  </ListItemButton>
                </Link>
                <Link href="/expected_incomes">
                  <ListItemButton selected={route.pathname === '/expected_incomes'}>
                    <ListItemIcon>
                      <Download fill={route.pathname === '/expected_incomes' ? '#1E95FF' : 'rgba(0, 0, 0, 0.54)'} />
                    </ListItemIcon>
                    <ListItemText primary="Ожидаемые поступления" />
                  </ListItemButton>
                </Link>
                <Link href="/deals">
                  <ListItemButton selected={route.pathname === '/deals'}>
                    <ListItemIcon>
                      <Deal fill={route.pathname === '/deals' ? '#1E95FF' : 'rgba(0, 0, 0, 0.54)'} />
                    </ListItemIcon>
                    <ListItemText primary="Сделки" />
                  </ListItemButton>
                </Link>
              </>
            )}
          </nav>
          <div>
            <Button
              endIcon={<LogoutIcon />}
              onClick={logout}
              sx={{
                color: '#676767',
                fontSize: 12,
                fontWeight: 500,
                whiteSpace: 'break-spaces',
              }}
            >
              {fullName}
            </Button>
            <ListItemButton onClick={setOpen}>
              <ListItemIcon>
                {open ? <Arrow /> : <ArrowRight />}
              </ListItemIcon>
              <ListItemText primary="Свернуть" />
            </ListItemButton>
          </div>
        </CustomListSideBar>
      </StyledDrawer>
    </section>
  );
};

export default SidebarComponent;
