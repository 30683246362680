import {
  put, takeEvery, call,
} from 'redux-saga/effects';

import api from 'src/lib/api';
import { actions as snackbar } from 'src/store/snackbar/slice';
import { actions as entityActions } from 'src/store/entity/slice';
import { actions } from 'src/store/dealShipment/slice';
import getResponseErrorMessage from 'src/lib/utils';
import getSnackbarMessage from 'src/lib/snackbar';
import parseRequest from 'src/lib/parser/request';

const entityType = 'DealShipmentEntity';

const parseRequestData = (data) => parseRequest({ entityType, data });

function* createDealShipment({ payload }) {
  try {
    yield call(api.post, 'deals/shipments/', { data: parseRequestData(payload) });
    yield put(entityActions.getListStart({ entityType, values: { deal: payload.deal } }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

function* editDealShipment({ payload }) {
  try {
    yield call(api.patch, `deals/shipments/${payload.id}/`, { data: parseRequestData(payload) });
    yield put(entityActions.getListStart({ entityType, values: { deal: payload.deal } }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

function* deleteDealShipment({ payload }) {
  try {
    yield call(api.delete, `deals/shipments/${payload.id}/`, {});
    yield put(entityActions.getListStart({ entityType, values: { deal: payload.deal } }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

export default function* sagas() {
  yield takeEvery(actions.createDealShipmentStart, createDealShipment);
  yield takeEvery(actions.editDealShipmentStart, editDealShipment);
  yield takeEvery(actions.deleteDealShipmentStart, deleteDealShipment);
}
