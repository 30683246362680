import { createSlice } from '@reduxjs/toolkit';
import { merge, reduce } from 'lodash';

import { ENTITY_TYPES } from 'src/constants';

const initialState = reduce(
  ENTITY_TYPES,
  (result) => {
    result = merge(
      {
        manager: null,
        loading: false,
      },
    );
    return result;
  },
  {},
);

const slice = createSlice({
  name: 'manager',
  initialState,
  reducers: {
    createManagerStart(state, { payload }) { },
    createManagerSuccess(state, { payload }) { },
    createManagerFailure(state, { payload }) { },
    getManagerStart(state, { payload }) {
      state.loading = true;
    },
    getManagerSuccess(state, { payload }) {
      const { response } = payload;
      state.manager = response;
      state.loading = false;
    },
    getManagerFailure(state, { payload }) { },
    editManagerStart(state, { payload }) {
      state.loading = true;
    },
    editManagerSuccess(state, { payload }) {
      const { response } = payload;
      state.loading = false;
      state.manager = response;
    },
    editManagerFailure(state, { payload }) { },
  },
});

export const { actions } = slice;

export default slice.reducer;
