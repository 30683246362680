import { createSlice } from '@reduxjs/toolkit';
import { merge, reduce } from 'lodash';

import { ENTITY_TYPES } from 'src/constants';

const initialState = reduce(
  ENTITY_TYPES,
  (result) => {
    result = merge(
      {
        cashDeposit: null,
        loading: false,
      },
    );
    return result;
  },
  {},
);

const slice = createSlice({
  name: 'cashDeposit',
  initialState,
  reducers: {
    createCashDepositStart(state, { payload }) {},
    createCashDepositSuccess(state, { payload }) { },
    createCashDepositFailure(state, { payload }) { },
    getCashDepositStart(state, { payload }) {
      state.loading = true;
    },
    getCashDepositSuccess(state, { payload }) {
      const { response } = payload;
      state.cashDeposit = response;
      state.loading = false;
    },
    getCashDepositFailure(state, { payload }) { },
    editCashDepositStart(state, { payload }) {
      state.loading = true;
    },
    editCashDepositSuccess(state, { payload }) {
      const { response } = payload;
      state.loading = false;
      state.cashDeposit = response;
    },
    editCashDepositFailure(state, { payload }) { },
  },
});

export const { actions } = slice;

export default slice.reducer;
