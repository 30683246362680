import {
  SetStateAction, Dispatch, ReactElement, cloneElement,
} from 'react';
import { Modal } from '@mui/material';
import { PopupContainer } from 'src/components/Layout';

interface PopupProps {
  open?: boolean | string,
  handleClose?: Dispatch<SetStateAction<any>>,
  children?: ReactElement
  translate?: string
  isOverflow?: boolean
  isMobile?: boolean
  sx?:object
}

const ModalWindow = ({
  open, handleClose, children, translate = 'translate(-50%, -50%)', isOverflow, isMobile, sx,
}: PopupProps) => (
  <Modal
    open={!!open}
    onClose={handleClose}
    sx={isOverflow && { overflow: 'scroll' }}
  >
    <PopupContainer
      sx={sx}
      isMobile={isMobile}
      id="modal-window"
      transform={translate}
    >
      {cloneElement(children, { handleClose })}
    </PopupContainer>
  </Modal>
);

export default ModalWindow;
