import {
  put, takeEvery, call, select,
} from 'redux-saga/effects';

import api from 'src/lib/api';
import getEntityProps from 'src/entities/props';
import { actions as snackbar } from 'src/store/snackbar/slice';
import { actions as entityActions } from 'src/store/entity/slice';

import getResponseErrorMessage from 'src/lib/utils';
import getSnackbarMessage from 'src/lib/snackbar';
import { ENTITY_TYPES } from 'src/constants';
import { diff } from 'src/lib/helper';
import { actions } from './slice';
import parseRequest from '../../lib/parser/request';

const entityType = ENTITY_TYPES.WALLET_ENTITY;
const parseRequestData = (data) => parseRequest({ entityType, data });

function* createWallet({ payload }) {
  const data = parseRequestData(payload);
  try {
    const endpoint = getEntityProps([entityType, 'endpoint']);
    const response = yield call(api.post, endpoint, { data });
    yield put(snackbar.openSuccessSnackbar(`Выплата ${response.id} успешно создана`));
    yield put(entityActions.getListStart(
      { entityType, values: { deal: data.deal } },
    ));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

function* getWallet({ payload }) {
  const { id } = payload;
  try {
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}${id}/`;
    const response = yield call(api.get, endpoint, {});
    yield put(actions.getWalletSuccess({ response }));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

function* editWallet({ payload }) {
  const { id } = payload;
  const data = parseRequestData(payload);
  try {
    const initialValues = yield select((store) =>
      store.wallet.wallet);
    const endpoint = `${getEntityProps([entityType, 'endpoint'])}${id}/`;
    const response = yield call(api.patch, endpoint, {
      data: parseRequestData(diff(initialValues, payload)),
    });
    yield put(actions.editWalletSuccess({ response }));
    yield put(snackbar.openSuccessSnackbar(`Выплата ${response.id} успешно обновлена`));
    yield put(entityActions.getListStart(
      { entityType, values: { deal: data.deal } },
    ));
  } catch (e) {
    const message = getSnackbarMessage({ messageType: 'getListError' });
    yield put(snackbar.openErrorSnackbar(getResponseErrorMessage(e, message)));
  }
}

export default function* sagas() {
  yield takeEvery(actions.createWalletStart, createWallet);
  yield takeEvery(actions.getWalletStart, getWallet);
  yield takeEvery(actions.editWalletStart, editWallet);
}
